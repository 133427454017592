/* components/Buger.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	listen,
	run_all,
	safe_not_equal,
	set_style,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

function create_fragment(ctx) {
	let div3;
	let div0;
	let button;
	let t2;
	let div2;
	let div1;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			button = element("button");

			button.innerHTML = `<span aria-hidden="true"></span> 
      <span aria-hidden="true"></span> 
      <span aria-hidden="true"></span>`;

			t2 = space();
			div2 = element("div");
			div1 = element("div");
			if (default_slot) default_slot.c();
			attr(button, "class", "button navbar-burger is-small show");
			attr(button, "aria-label", "menu");
			attr(button, "aria-expanded", "false");
			toggle_class(button, "is-active", /*isActive*/ ctx[0]);
			attr(div0, "class", "dropdown-trigger");
			attr(div1, "class", "dropdown-content");
			set_style(div1, "padding", "0.5rem");
			attr(div2, "class", "dropdown-menu");
			attr(div2, "id", "dropdown-menu");
			attr(div2, "role", "menu");
			attr(div3, "class", "dropdown is-right");
			toggle_class(div3, "is-active", /*isActive*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div0, button);
			append(div3, t2);
			append(div3, div2);
			append(div2, div1);

			if (default_slot) {
				default_slot.m(div1, null);
			}

			/*div3_binding*/ ctx[7](div3);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "click", /*handleClickOutside*/ ctx[2]),
					listen(button, "click", /*click_handler*/ ctx[5]),
					listen(div2, "click", /*click_handler_1*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*isActive*/ 1) {
				toggle_class(button, "is-active", /*isActive*/ ctx[0]);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*isActive*/ 1) {
				toggle_class(div3, "is-active", /*isActive*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (default_slot) default_slot.d(detaching);
			/*div3_binding*/ ctx[7](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let isActive = false;
	let dropdownElement;

	const handleClickOutside = e => {
		if (isActive && !dropdownElement.contains(e.target)) {
			$$invalidate(0, isActive = false);
		}
	};

	const click_handler = () => {
		$$invalidate(0, isActive = !isActive);
	};

	const click_handler_1 = () => {
		$$invalidate(0, isActive = false);
	};

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			dropdownElement = $$value;
			$$invalidate(1, dropdownElement);
		});
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [
		isActive,
		dropdownElement,
		handleClickOutside,
		$$scope,
		slots,
		click_handler,
		click_handler_1,
		div3_binding
	];
}

class Buger extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Buger;