/* App.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	get_spread_object,
	get_spread_update,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "svelte/internal";

import { Router, Route } from "svelte-routing";
import NavLink from "./components/NavLink.svelte";
import Toast from "./components/Toast.svelte";
import Play from "./routes/Play.svelte";
import Comp from "./routes/Comp.svelte";
import Publish from "./routes/Publish.svelte";
import Create from "./routes/Create.svelte";
import Duty from "./routes/Duty.svelte";
import Route404 from "./routes/404.svelte";
import { hideNavBar } from "./store/store";
import compCreatorIcon from "./assets/siteIcon/favicon-32x32.png";
import Help from "./routes/Help.svelte";

function create_if_block(ctx) {
	let div0;
	let t0;
	let t1;
	let nav;
	let div1;
	let navlink0;
	let t2;
	let a;
	let t5;
	let div3;
	let div2;
	let navlink1;
	let t6;
	let navlink2;
	let t7;
	let navlink3;
	let t8;
	let navlink4;
	let current;
	let mounted;
	let dispose;

	navlink0 = new NavLink({
			props: {
				to: "comps",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	navlink1 = new NavLink({
			props: {
				to: "comps",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	navlink2 = new NavLink({
			props: {
				to: "create",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	navlink3 = new NavLink({
			props: {
				to: "publish",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	navlink4 = new NavLink({
			props: {
				to: "help",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div0 = element("div");
			t0 = text(/*url*/ ctx[0]);
			t1 = space();
			nav = element("nav");
			div1 = element("div");
			create_component(navlink0.$$.fragment);
			t2 = space();
			a = element("a");

			a.innerHTML = `<span aria-hidden="true"></span> 
            <span aria-hidden="true"></span> 
            <span aria-hidden="true"></span>`;

			t5 = space();
			div3 = element("div");
			div2 = element("div");
			create_component(navlink1.$$.fragment);
			t6 = space();
			create_component(navlink2.$$.fragment);
			t7 = space();
			create_component(navlink3.$$.fragment);
			t8 = space();
			create_component(navlink4.$$.fragment);
			attr(a, "role", "button");
			attr(a, "class", "navbar-burger burger");
			attr(a, "aria-label", "menu");
			attr(a, "aria-expanded", "false");
			attr(a, "data-target", "navbarBasicExample");
			attr(div1, "class", "navbar-brand");
			attr(div2, "class", "navbar-start");
			attr(div3, "id", "navbarBasicExample");
			attr(div3, "class", "navbar-menu");
			toggle_class(div3, "is-active", /*menuActive*/ ctx[2]);
			attr(nav, "class", "navbar");
			attr(nav, "role", "navigation");
			attr(nav, "aria-label", "main navigation");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, t0);
			insert(target, t1, anchor);
			insert(target, nav, anchor);
			append(nav, div1);
			mount_component(navlink0, div1, null);
			append(div1, t2);
			append(div1, a);
			append(nav, t5);
			append(nav, div3);
			append(div3, div2);
			mount_component(navlink1, div2, null);
			append(div2, t6);
			mount_component(navlink2, div2, null);
			append(div2, t7);
			mount_component(navlink3, div2, null);
			append(div2, t8);
			mount_component(navlink4, div2, null);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", /*click_handler*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*url*/ 1) set_data(t0, /*url*/ ctx[0]);
			const navlink0_changes = {};

			if (dirty & /*$$scope*/ 64) {
				navlink0_changes.$$scope = { dirty, ctx };
			}

			navlink0.$set(navlink0_changes);
			const navlink1_changes = {};

			if (dirty & /*$$scope*/ 64) {
				navlink1_changes.$$scope = { dirty, ctx };
			}

			navlink1.$set(navlink1_changes);
			const navlink2_changes = {};

			if (dirty & /*$$scope*/ 64) {
				navlink2_changes.$$scope = { dirty, ctx };
			}

			navlink2.$set(navlink2_changes);
			const navlink3_changes = {};

			if (dirty & /*$$scope*/ 64) {
				navlink3_changes.$$scope = { dirty, ctx };
			}

			navlink3.$set(navlink3_changes);
			const navlink4_changes = {};

			if (dirty & /*$$scope*/ 64) {
				navlink4_changes.$$scope = { dirty, ctx };
			}

			navlink4.$set(navlink4_changes);

			if (!current || dirty & /*menuActive*/ 4) {
				toggle_class(div3, "is-active", /*menuActive*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(navlink0.$$.fragment, local);
			transition_in(navlink1.$$.fragment, local);
			transition_in(navlink2.$$.fragment, local);
			transition_in(navlink3.$$.fragment, local);
			transition_in(navlink4.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navlink0.$$.fragment, local);
			transition_out(navlink1.$$.fragment, local);
			transition_out(navlink2.$$.fragment, local);
			transition_out(navlink3.$$.fragment, local);
			transition_out(navlink4.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(nav);
			destroy_component(navlink0);
			destroy_component(navlink1);
			destroy_component(navlink2);
			destroy_component(navlink3);
			destroy_component(navlink4);
			mounted = false;
			dispose();
		}
	};
}

// (30:10) <NavLink to="comps">
function create_default_slot_7(ctx) {
	let span1;
	let img;
	let img_src_value;
	let t0;
	let span0;

	return {
		c() {
			span1 = element("span");
			img = element("img");
			t0 = space();
			span0 = element("span");
			span0.textContent = "The Comp Creator";
			attr(img, "class", "");
			if (!src_url_equal(img.src, img_src_value = compCreatorIcon)) attr(img, "src", img_src_value);
			attr(img, "alt", "icon");
			attr(span0, "class", "");
			attr(span1, "class", "");
			set_style(span1, "color", "rgb(50,115,220)");
			set_style(span1, "display", "flex");
			set_style(span1, "align-items", "center");
		},
		m(target, anchor) {
			insert(target, span1, anchor);
			append(span1, img);
			append(span1, t0);
			append(span1, span0);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(span1);
		}
	};
}

// (54:12) <NavLink to="comps">
function create_default_slot_6(ctx) {
	let t;

	return {
		c() {
			t = text("Play");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (55:12) <NavLink to="create">
function create_default_slot_5(ctx) {
	let t;

	return {
		c() {
			t = text("Create");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (56:12) <NavLink to="publish">
function create_default_slot_4(ctx) {
	let t;

	return {
		c() {
			t = text("Publish");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (57:12) <NavLink to="help">
function create_default_slot_3(ctx) {
	let t;

	return {
		c() {
			t = text("Help!");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (64:4) <Route path="comps/:compId" let:params>
function create_default_slot_2(ctx) {
	let comp;
	let current;

	comp = new Comp({
			props: { compId: /*params*/ ctx[5].compId }
		});

	return {
		c() {
			create_component(comp.$$.fragment);
		},
		m(target, anchor) {
			mount_component(comp, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const comp_changes = {};
			if (dirty & /*params*/ 32) comp_changes.compId = /*params*/ ctx[5].compId;
			comp.$set(comp_changes);
		},
		i(local) {
			if (current) return;
			transition_in(comp.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(comp.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(comp, detaching);
		}
	};
}

// (67:4) <Route path="comps/:compId/games/:gameId/duty" let:params>
function create_default_slot_1(ctx) {
	let duty;
	let current;
	const duty_spread_levels = [/*params*/ ctx[5]];
	let duty_props = {};

	for (let i = 0; i < duty_spread_levels.length; i += 1) {
		duty_props = assign(duty_props, duty_spread_levels[i]);
	}

	duty = new Duty({ props: duty_props });

	return {
		c() {
			create_component(duty.$$.fragment);
		},
		m(target, anchor) {
			mount_component(duty, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const duty_changes = (dirty & /*params*/ 32)
			? get_spread_update(duty_spread_levels, [get_spread_object(/*params*/ ctx[5])])
			: {};

			duty.$set(duty_changes);
		},
		i(local) {
			if (current) return;
			transition_in(duty.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(duty.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(duty, detaching);
		}
	};
}

// (23:0) <Router {url} {basepath}>
function create_default_slot(ctx) {
	let toast;
	let t0;
	let div;
	let t1;
	let main;
	let route0;
	let t2;
	let route1;
	let t3;
	let route2;
	let t4;
	let route3;
	let t5;
	let route4;
	let t6;
	let route5;
	let t7;
	let route6;
	let current;
	toast = new Toast({});
	let if_block = !/*$hideNavBar*/ ctx[3] && create_if_block(ctx);

	route0 = new Route({
			props: {
				path: "comps/:compId",
				$$slots: {
					default: [
						create_default_slot_2,
						({ params }) => ({ 5: params }),
						({ params }) => params ? 32 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route1 = new Route({
			props: {
				path: "comps/:compId/games/:gameId/duty",
				$$slots: {
					default: [
						create_default_slot_1,
						({ params }) => ({ 5: params }),
						({ params }) => params ? 32 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route2 = new Route({
			props: { path: "publish", component: Publish }
		});

	route3 = new Route({
			props: { path: "create", component: Create }
		});

	route4 = new Route({
			props: { path: "comps", component: Play }
		});

	route5 = new Route({ props: { path: "help", component: Help } });
	route6 = new Route({ props: { path: "", component: Route404 } });

	return {
		c() {
			create_component(toast.$$.fragment);
			t0 = space();
			div = element("div");
			if (if_block) if_block.c();
			t1 = space();
			main = element("main");
			create_component(route0.$$.fragment);
			t2 = space();
			create_component(route1.$$.fragment);
			t3 = space();
			create_component(route2.$$.fragment);
			t4 = space();
			create_component(route3.$$.fragment);
			t5 = space();
			create_component(route4.$$.fragment);
			t6 = space();
			create_component(route5.$$.fragment);
			t7 = space();
			create_component(route6.$$.fragment);
			attr(div, "class", "is-hidden-print container mb-0 title is-5");
			attr(main, "class", "container");
		},
		m(target, anchor) {
			mount_component(toast, target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			insert(target, t1, anchor);
			insert(target, main, anchor);
			mount_component(route0, main, null);
			append(main, t2);
			mount_component(route1, main, null);
			append(main, t3);
			mount_component(route2, main, null);
			append(main, t4);
			mount_component(route3, main, null);
			append(main, t5);
			mount_component(route4, main, null);
			append(main, t6);
			mount_component(route5, main, null);
			append(main, t7);
			mount_component(route6, main, null);
			current = true;
		},
		p(ctx, dirty) {
			if (!/*$hideNavBar*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$hideNavBar*/ 8) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const route0_changes = {};

			if (dirty & /*$$scope, params*/ 96) {
				route0_changes.$$scope = { dirty, ctx };
			}

			route0.$set(route0_changes);
			const route1_changes = {};

			if (dirty & /*$$scope, params*/ 96) {
				route1_changes.$$scope = { dirty, ctx };
			}

			route1.$set(route1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(toast.$$.fragment, local);
			transition_in(if_block);
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			transition_in(route3.$$.fragment, local);
			transition_in(route4.$$.fragment, local);
			transition_in(route5.$$.fragment, local);
			transition_in(route6.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(toast.$$.fragment, local);
			transition_out(if_block);
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			transition_out(route3.$$.fragment, local);
			transition_out(route4.$$.fragment, local);
			transition_out(route5.$$.fragment, local);
			transition_out(route6.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(toast, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			if (if_block) if_block.d();
			if (detaching) detach(t1);
			if (detaching) detach(main);
			destroy_component(route0);
			destroy_component(route1);
			destroy_component(route2);
			destroy_component(route3);
			destroy_component(route4);
			destroy_component(route5);
			destroy_component(route6);
		}
	};
}

function create_fragment(ctx) {
	let router;
	let current;

	router = new Router({
			props: {
				url: /*url*/ ctx[0],
				basepath: /*basepath*/ ctx[1],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};
			if (dirty & /*url*/ 1) router_changes.url = /*url*/ ctx[0];
			if (dirty & /*basepath*/ 2) router_changes.basepath = /*basepath*/ ctx[1];

			if (dirty & /*$$scope, menuActive, url, $hideNavBar*/ 77) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $hideNavBar;
	component_subscribe($$self, hideNavBar, $$value => $$invalidate(3, $hideNavBar = $$value));
	let { url = "" } = $$props;
	let { basepath = "/" } = $$props;
	let menuActive = false;
	const click_handler = () => $$invalidate(2, menuActive = !menuActive);

	$$self.$$set = $$props => {
		if ('url' in $$props) $$invalidate(0, url = $$props.url);
		if ('basepath' in $$props) $$invalidate(1, basepath = $$props.basepath);
	};

	return [url, basepath, menuActive, $hideNavBar, click_handler];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { url: 0, basepath: 1 });
	}
}

export default App;