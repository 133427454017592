/* components/Ladder.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (23:4) {#each ladder as rank}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*teams*/ ctx[1][/*rank*/ ctx[2].team].name + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*rank*/ ctx[2].gamesPlayed + "";
	let t2;
	let t3;
	let td2;
	let t4_value = /*rank*/ ctx[2].wins + "";
	let t4;
	let t5;
	let td3;
	let t6_value = /*rank*/ ctx[2].draws + "";
	let t6;
	let t7;
	let td4;
	let t8_value = /*rank*/ ctx[2].losses + "";
	let t8;
	let t9;
	let td5;
	let t10_value = /*rank*/ ctx[2].forfeits + "";
	let t10;
	let t11;
	let td6;
	let t12_value = /*rank*/ ctx[2].goalsFor + "";
	let t12;
	let t13;
	let td7;
	let t14_value = /*rank*/ ctx[2].goalsAgainst + "";
	let t14;
	let t15;
	let td8;
	let t16_value = /*rank*/ ctx[2].points + "";
	let t16;
	let t17;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td3 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td4 = element("td");
			t8 = text(t8_value);
			t9 = space();
			td5 = element("td");
			t10 = text(t10_value);
			t11 = space();
			td6 = element("td");
			t12 = text(t12_value);
			t13 = space();
			td7 = element("td");
			t14 = text(t14_value);
			t15 = space();
			td8 = element("td");
			t16 = text(t16_value);
			t17 = space();
			attr(td0, "class", "is-capitalized");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
			append(tr, td3);
			append(td3, t6);
			append(tr, t7);
			append(tr, td4);
			append(td4, t8);
			append(tr, t9);
			append(tr, td5);
			append(td5, t10);
			append(tr, t11);
			append(tr, td6);
			append(td6, t12);
			append(tr, t13);
			append(tr, td7);
			append(td7, t14);
			append(tr, t15);
			append(tr, td8);
			append(td8, t16);
			append(tr, t17);
		},
		p(ctx, dirty) {
			if (dirty & /*teams, ladder*/ 3 && t0_value !== (t0_value = /*teams*/ ctx[1][/*rank*/ ctx[2].team].name + "")) set_data(t0, t0_value);
			if (dirty & /*ladder*/ 1 && t2_value !== (t2_value = /*rank*/ ctx[2].gamesPlayed + "")) set_data(t2, t2_value);
			if (dirty & /*ladder*/ 1 && t4_value !== (t4_value = /*rank*/ ctx[2].wins + "")) set_data(t4, t4_value);
			if (dirty & /*ladder*/ 1 && t6_value !== (t6_value = /*rank*/ ctx[2].draws + "")) set_data(t6, t6_value);
			if (dirty & /*ladder*/ 1 && t8_value !== (t8_value = /*rank*/ ctx[2].losses + "")) set_data(t8, t8_value);
			if (dirty & /*ladder*/ 1 && t10_value !== (t10_value = /*rank*/ ctx[2].forfeits + "")) set_data(t10, t10_value);
			if (dirty & /*ladder*/ 1 && t12_value !== (t12_value = /*rank*/ ctx[2].goalsFor + "")) set_data(t12, t12_value);
			if (dirty & /*ladder*/ 1 && t14_value !== (t14_value = /*rank*/ ctx[2].goalsAgainst + "")) set_data(t14, t14_value);
			if (dirty & /*ladder*/ 1 && t16_value !== (t16_value = /*rank*/ ctx[2].points + "")) set_data(t16, t16_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let table;
	let thead;
	let t17;
	let tbody;
	let each_value = /*ladder*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><td>Team</td> 
      <td title="Games Played">GP</td> 
      <td>Wins</td> 
      <td>Draws</td> 
      <td>Losses</td> 
      <td>Forfeits</td> 
      <td title="Goals For">GF</td> 
      <td title="Goals Against">GA</td> 
      <td title="Points = (Wins x 3) + (Draws x 1) + (Losses x 0)">Points</td></tr>`;

			t17 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(table, "class", "table is-striped is-hoverable is-fullwidth is-centered-cells");
			set_style(table, "font-size", ".8rem");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, thead);
			append(table, t17);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*ladder, teams*/ 3) {
				each_value = /*ladder*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(table);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { ladder = [] } = $$props;
	let { teams = {} } = $$props;

	$$self.$$set = $$props => {
		if ('ladder' in $$props) $$invalidate(0, ladder = $$props.ladder);
		if ('teams' in $$props) $$invalidate(1, teams = $$props.teams);
	};

	return [ladder, teams];
}

class Ladder extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { ladder: 0, teams: 1 });
	}
}

export default Ladder;