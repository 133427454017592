/* components/CreatePages/Pools.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import xor from "lodash/xor";
import Pool from "./Pool.svelte";
import { isSameName, createPool } from "../../utils/createCompUtils";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (47:2) {#if poolError}
function create_if_block(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*poolError*/ ctx[2]);
			attr(div, "class", "help");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*poolError*/ 4) set_data(t, /*poolError*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (51:0) {#each pools as pool}
function create_each_block(ctx) {
	let pool;
	let current;
	pool = new Pool({ props: { pool: /*pool*/ ctx[8] } });

	return {
		c() {
			create_component(pool.$$.fragment);
		},
		m(target, anchor) {
			mount_component(pool, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const pool_changes = {};
			if (dirty & /*pools*/ 1) pool_changes.pool = /*pool*/ ctx[8];
			pool.$set(pool_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pool.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pool.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(pool, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let label;
	let t1;
	let div0;
	let input;
	let t2;
	let t3;
	let each_1_anchor;
	let current;
	let mounted;
	let dispose;
	let if_block = /*poolError*/ ctx[2] && create_if_block(ctx);
	let each_value = /*pools*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div1 = element("div");
			label = element("label");
			label.innerHTML = `<small><i>Press enter to add a new pool.</i></small>`;
			t1 = space();
			div0 = element("div");
			input = element("input");
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(label, "class", "label");
			attr(label, "for", "poolInput");
			attr(input, "name", "poolInput");
			attr(input, "class", "input");
			attr(input, "type", "text");
			attr(input, "placeholder", "Enter pool name");
			attr(div0, "class", "control");
			set_style(div0, "max-width", "20rem");
			attr(div1, "class", "field");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, label);
			append(div1, t1);
			append(div1, div0);
			append(div0, input);
			set_input_value(input, /*poolName*/ ctx[1]);
			append(div1, t2);
			if (if_block) if_block.m(div1, null);
			insert(target, t3, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[5]),
					listen(input, "keypress", /*keypress_handler*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*poolName*/ 2 && input.value !== /*poolName*/ ctx[1]) {
				set_input_value(input, /*poolName*/ ctx[1]);
			}

			if (/*poolError*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*pools*/ 1) {
				each_value = /*pools*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			if (detaching) detach(t3);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { pools = [] } = $$props;
	let { teams = [] } = $$props;
	let poolName = "";
	let poolError;
	let teamsAdded = [];

	const addPool = event => {
		if (event.key !== "Enter") {
			return;
		}

		if (poolName.trim() === "") {
			$$invalidate(2, poolError = "Empty pool name.");
		}

		if (pools.find(p => isSameName(poolName, p.name)) !== undefined) {
			$$invalidate(2, poolError = "Duplicate team. Every team must have a unique name.");
		} else {
			$$invalidate(2, poolError = undefined);
			const remainingTeams = xor(teamsAdded, teams);
			$$invalidate(0, pools = [...pools, createPool(poolName, remainingTeams)]);
			teamsAdded = [].concat(teamsAdded, remainingTeams);
			$$invalidate(1, poolName = "");
		}
	};

	function input_input_handler() {
		poolName = this.value;
		$$invalidate(1, poolName);
	}

	const keypress_handler = e => addPool(e);

	$$self.$$set = $$props => {
		if ('pools' in $$props) $$invalidate(0, pools = $$props.pools);
		if ('teams' in $$props) $$invalidate(4, teams = $$props.teams);
	};

	return [
		pools,
		poolName,
		poolError,
		addPool,
		teams,
		input_input_handler,
		keypress_handler
	];
}

class Pools extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { pools: 0, teams: 4 });
	}
}

export default Pools;