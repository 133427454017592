/* components/CardModal.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "svelte/internal";

import { post } from "../utils/api";
import { severityTypes, cardTypes, severityToColor } from "../utils/dutyUtils";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[17] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[17] = list[i];
	return child_ctx;
}

// (58:14) {#each severityTypes as option}
function create_each_block_1(ctx) {
	let label;
	let input;
	let input_value_value;
	let t0;
	let t1_value = /*option*/ ctx[17] + "";
	let t1;
	let t2;
	let label_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			label = element("label");
			input = element("input");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			attr(input, "type", "radio");
			input.__value = input_value_value = /*option*/ ctx[17];
			input.value = input.__value;
			/*$$binding_groups*/ ctx[11][0].push(input);
			attr(label, "class", label_class_value = `radio is-capitalized has-text-${severityToColor[/*option*/ ctx[17]]}`);
		},
		m(target, anchor) {
			insert(target, label, anchor);
			append(label, input);
			input.checked = input.__value === /*severity*/ ctx[3];
			append(label, t0);
			append(label, t1);
			append(label, t2);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler*/ ctx[10]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*severity*/ 8) {
				input.checked = input.__value === /*severity*/ ctx[3];
			}
		},
		d(detaching) {
			if (detaching) detach(label);
			/*$$binding_groups*/ ctx[11][0].splice(/*$$binding_groups*/ ctx[11][0].indexOf(input), 1);
			mounted = false;
			dispose();
		}
	};
}

// (68:6) {#if player}
function create_if_block(ctx) {
	let div5;
	let div0;
	let t0;
	let div4;
	let div3;
	let div2;
	let label1;
	let input;
	let t1;
	let t2;
	let div1;
	let t3;
	let p;

	let t4_value = (/*ejection*/ ctx[4]
	? "Player is off for the full 2 minutes."
	: "Player is off until opposing team scores OR 2 minutes.") + "";

	let t4;
	let mounted;
	let dispose;

	return {
		c() {
			div5 = element("div");
			div0 = element("div");
			div0.innerHTML = `<label class="label" for="ejection"></label>`;
			t0 = space();
			div4 = element("div");
			div3 = element("div");
			div2 = element("div");
			label1 = element("label");
			input = element("input");
			t1 = text("\n                  Ejection red card or 3rd sanction");
			t2 = space();
			div1 = element("div");
			t3 = space();
			p = element("p");
			t4 = text(t4_value);
			attr(div0, "class", "field-label");
			attr(input, "type", "checkbox");
			attr(label1, "class", "checkbox");
			attr(div1, "class", "break");
			attr(p, "class", "help");
			attr(div2, "class", "control");
			attr(div2, "name", "ejection");
			attr(div3, "class", "field");
			attr(div4, "class", "field-body");
			attr(div5, "class", "field is-horizontal");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div0);
			append(div5, t0);
			append(div5, div4);
			append(div4, div3);
			append(div3, div2);
			append(div2, label1);
			append(label1, input);
			input.checked = /*ejection*/ ctx[4];
			append(label1, t1);
			append(div2, t2);
			append(div2, div1);
			append(div2, t3);
			append(div2, p);
			append(p, t4);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler_1*/ ctx[12]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*ejection*/ 16) {
				input.checked = /*ejection*/ ctx[4];
			}

			if (dirty & /*ejection*/ 16 && t4_value !== (t4_value = (/*ejection*/ ctx[4]
			? "Player is off for the full 2 minutes."
			: "Player is off until opposing team scores OR 2 minutes.") + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(div5);
			mounted = false;
			dispose();
		}
	};
}

// (98:14) {#each cardTypes as option}
function create_each_block(ctx) {
	let label;
	let input;
	let input_value_value;
	let t0;
	let t1_value = /*option*/ ctx[17] + "";
	let t1;
	let t2;
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			label = element("label");
			input = element("input");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			div = element("div");
			attr(input, "type", "radio");
			input.__value = input_value_value = /*option*/ ctx[17];
			input.value = input.__value;
			/*$$binding_groups*/ ctx[11][1].push(input);
			attr(label, "class", "radio is-capitalized");
			attr(div, "class", "break");
		},
		m(target, anchor) {
			insert(target, label, anchor);
			append(label, input);
			input.checked = input.__value === /*type*/ ctx[2];
			append(label, t0);
			append(label, t1);
			insert(target, t2, anchor);
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler_2*/ ctx[13]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*type*/ 4) {
				input.checked = input.__value === /*type*/ ctx[2];
			}
		},
		d(detaching) {
			if (detaching) detach(label);
			/*$$binding_groups*/ ctx[11][1].splice(/*$$binding_groups*/ ctx[11][1].indexOf(input), 1);
			if (detaching) detach(t2);
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div12;
	let div0;
	let t0;
	let div11;
	let header;
	let p;
	let span0;
	let t2;
	let span1;
	let t3_value = /*getTitle*/ ctx[5]() + "";
	let t3;
	let t4;
	let button0;
	let t5;
	let section;
	let div5;
	let div1;
	let t7;
	let div4;
	let div3;
	let div2;
	let t8;
	let t9;
	let div10;
	let div6;
	let t11;
	let div9;
	let div8;
	let div7;
	let t12;
	let footer;
	let button1;
	let t14;
	let button2;
	let t15_value = (/*player*/ ctx[1] ? "Card Player" : "Card Team") + "";
	let t15;
	let mounted;
	let dispose;
	let each_value_1 = severityTypes;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let if_block = /*player*/ ctx[1] && create_if_block(ctx);
	let each_value = cardTypes;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div12 = element("div");
			div0 = element("div");
			t0 = space();
			div11 = element("div");
			header = element("header");
			p = element("p");
			span0 = element("span");
			span0.textContent = "Card:";
			t2 = space();
			span1 = element("span");
			t3 = text(t3_value);
			t4 = space();
			button0 = element("button");
			t5 = space();
			section = element("section");
			div5 = element("div");
			div1 = element("div");
			div1.innerHTML = `<label class="label" for="selectSev">Card</label>`;
			t7 = space();
			div4 = element("div");
			div3 = element("div");
			div2 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t8 = space();
			if (if_block) if_block.c();
			t9 = space();
			div10 = element("div");
			div6 = element("div");
			div6.innerHTML = `<label class="label" for="type">Foul:</label>`;
			t11 = space();
			div9 = element("div");
			div8 = element("div");
			div7 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t12 = space();
			footer = element("footer");
			button1 = element("button");
			button1.textContent = "Cancel";
			t14 = space();
			button2 = element("button");
			t15 = text(t15_value);
			attr(div0, "class", "modal-background");
			attr(span0, "class", "has-text-grey");
			attr(span1, "class", "title is-capitalized");
			attr(p, "class", "modal-card-title");
			attr(button0, "class", "delete");
			attr(button0, "aria-label", "close");
			attr(header, "class", "modal-card-head");
			attr(div1, "class", "field-label");
			attr(div2, "class", "control");
			attr(div2, "name", "selectSev");
			attr(div3, "class", "field");
			attr(div4, "class", "field-body");
			attr(div5, "class", "field is-horizontal");
			attr(div6, "class", "field-label");
			attr(div7, "class", "control");
			attr(div7, "name", "type");
			attr(div8, "class", "field");
			attr(div9, "class", "field-body");
			attr(div10, "class", "field is-horizontal");
			attr(section, "class", "modal-card-body");
			attr(button1, "class", "button");
			attr(button2, "class", "button is-primary is-outlined");
			attr(footer, "class", "modal-card-foot");
			attr(div11, "class", "modal-card");
			attr(div12, "class", "modal");
			toggle_class(div12, "is-active", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div12, anchor);
			append(div12, div0);
			append(div12, t0);
			append(div12, div11);
			append(div11, header);
			append(header, p);
			append(p, span0);
			append(p, t2);
			append(p, span1);
			append(span1, t3);
			append(header, t4);
			append(header, button0);
			append(div11, t5);
			append(div11, section);
			append(section, div5);
			append(div5, div1);
			append(div5, t7);
			append(div5, div4);
			append(div4, div3);
			append(div3, div2);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(div2, null);
			}

			append(section, t8);
			if (if_block) if_block.m(section, null);
			append(section, t9);
			append(section, div10);
			append(div10, div6);
			append(div10, t11);
			append(div10, div9);
			append(div9, div8);
			append(div8, div7);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div7, null);
			}

			append(div11, t12);
			append(div11, footer);
			append(footer, button1);
			append(footer, t14);
			append(footer, button2);
			append(button2, t15);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[9]),
					listen(button1, "click", /*click_handler_1*/ ctx[14]),
					listen(button2, "click", /*giveCard*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*getTitle*/ 32 && t3_value !== (t3_value = /*getTitle*/ ctx[5]() + "")) set_data(t3, t3_value);

			if (dirty & /*severityToColor, severityTypes, severity*/ 8) {
				each_value_1 = severityTypes;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(div2, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (/*player*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(section, t9);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*cardTypes, type*/ 4) {
				each_value = cardTypes;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div7, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*player*/ 2 && t15_value !== (t15_value = (/*player*/ ctx[1] ? "Card Player" : "Card Team") + "")) set_data(t15, t15_value);

			if (dirty & /*open*/ 1) {
				toggle_class(div12, "is-active", /*open*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div12);
			destroy_each(each_blocks_1, detaching);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let getTitle;
	let { game } = $$props;
	let { team = {} } = $$props;
	let { player = {} } = $$props;
	let { open = false } = $$props;

	const resetModal = () => {
		$$invalidate(2, type = cardTypes[0]);
		$$invalidate(3, severity = severityTypes[0]);
		$$invalidate(4, ejection = false);
	};

	let type;
	let severity;
	let ejection;
	resetModal();
	const gamePath = `comps/${game.comp}/games/${game.id}`;

	const giveCard = async () => {
		$$invalidate(7, game = await post(gamePath + `/cards`, {
			timeRemainingSec: game.timeSec,
			playerUuid: (player || {}).uuid,
			teamUuid: team.uuid,
			type,
			severity,
			ejection
		}));

		resetModal();
		$$invalidate(0, open = false);
	};

	const $$binding_groups = [[], []];
	const click_handler = () => $$invalidate(0, open = false);

	function input_change_handler() {
		severity = this.__value;
		$$invalidate(3, severity);
	}

	function input_change_handler_1() {
		ejection = this.checked;
		$$invalidate(4, ejection);
	}

	function input_change_handler_2() {
		type = this.__value;
		$$invalidate(2, type);
	}

	const click_handler_1 = () => $$invalidate(0, open = false);

	$$self.$$set = $$props => {
		if ('game' in $$props) $$invalidate(7, game = $$props.game);
		if ('team' in $$props) $$invalidate(8, team = $$props.team);
		if ('player' in $$props) $$invalidate(1, player = $$props.player);
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*player, team*/ 258) {
			$: $$invalidate(5, getTitle = () => player ? player.name : `Team; ${team.name}`);
		}
	};

	return [
		open,
		player,
		type,
		severity,
		ejection,
		getTitle,
		giveCard,
		game,
		team,
		click_handler,
		input_change_handler,
		$$binding_groups,
		input_change_handler_1,
		input_change_handler_2,
		click_handler_1
	];
}

class CardModal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { game: 7, team: 8, player: 1, open: 0 });
	}
}

export default CardModal;