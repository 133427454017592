/* routes/Duty.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	assign,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	get_spread_object,
	get_spread_update,
	group_outros,
	handle_promise,
	init,
	insert,
	listen,
	mount_component,
	noop,
	null_to_empty,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	set_style,
	space,
	src_url_equal,
	stop_propagation,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_await_block_branch
} from "svelte/internal";

import _get from "lodash/get";
import { onMount, onDestroy } from "svelte";
import Icon from "fa-svelte";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { get, post, del } from "../utils/api";
import Loading from "../components/Loading.svelte";
import Timer from "../components/Timer.svelte";
import EditTeamModal from "../components/EditTeamModal.svelte";
import CardModal from "../components/CardModal.svelte";
import Buger from "../components/Buger.svelte";
import PasswordModal from "../components/PasswordModal.svelte";
import { hideNavBar, resetTimer } from "../store/store";
import FinishedModal from "../components/FinishedModal.svelte";

import {
	cardTypeAbbrev,
	severityToColor,
	getActiveCards,
	backToComp
} from "../utils/dutyUtils";

import { goalsForSlot } from "../utils/compUtils";
import CardBadge from "../components/CardBadge.svelte";
import hornSound from "../assets/horn.mp3";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[48] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[51] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[54] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[54] = list[i];
	return child_ctx;
}

// (1:0) <script>   import _get from "lodash/get";   import { onMount, onDestroy }
function create_catch_block(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (169:0) {:then g}
function create_then_block(ctx) {
	let audio;
	let audio_src_value;
	let t0;
	let h5;
	let div0;
	let t1_value = /*game*/ ctx[3].compName + "";
	let t1;
	let t2;
	let button0;
	let span0;
	let icon;
	let t3;
	let span1;
	let t5;
	let div11;
	let div6;
	let h60;
	let span2;
	let t7;
	let span3;
	let t8_value = /*game*/ ctx[3].poolName + "";
	let t8;
	let t9;
	let div1;
	let span4;
	let t11;
	let span5;
	let t12_value = /*game*/ ctx[3].id + "";
	let t12;
	let t13;
	let div2;
	let span6;
	let t15;
	let span7;
	let t16_value = /*getTeamName*/ ctx[11]("r1") + "";
	let t16;
	let t17;
	let div3;
	let span8;
	let t19;
	let span9;
	let t20_value = /*getTeamName*/ ctx[11]("r2") + "";
	let t20;
	let t21;
	let div4;
	let span10;
	let t23;
	let span11;
	let t24_value = /*getTeamName*/ ctx[11]("duty") + "";
	let t24;
	let t25;
	let div5;
	let h61;
	let span12;
	let t27;
	let span13;
	let t28_value = /*game*/ ctx[3].field + "";
	let t28;
	let t29;
	let div8;
	let div7;
	let button1;
	let t31;
	let button2;
	let t33;
	let button3;
	let t35;
	let button4;
	let t37;
	let div9;
	let timer;
	let t38;
	let section;
	let t39;
	let article;
	let div10;
	let button5;
	let t41;
	let button6;
	let t43;
	let editteammodal;
	let t44;
	let cardmodal;
	let updating_game;
	let t45;
	let finishedmodal;
	let updating_open;
	let current;
	let mounted;
	let dispose;
	icon = new Icon({ props: { class: "", icon: faAngleLeft } });

	timer = new Timer({
			props: { timeSec: /*game*/ ctx[3].timeSec }
		});

	timer.$on("tic", /*setGameTime*/ ctx[16]);
	timer.$on("finished", /*onTimerFinished*/ ctx[23]);
	timer.$on("started", /*onTimerStarted*/ ctx[24]);
	let each_value = ["t1", "t2"];
	let each_blocks = [];

	for (let i = 0; i < 2; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	const editteammodal_spread_levels = [/*teamModalProps*/ ctx[6]];
	let editteammodal_props = {};

	for (let i = 0; i < editteammodal_spread_levels.length; i += 1) {
		editteammodal_props = assign(editteammodal_props, editteammodal_spread_levels[i]);
	}

	editteammodal = new EditTeamModal({ props: editteammodal_props });
	editteammodal.$on("updated", /*onTeamUpdated*/ ctx[13]);
	const cardmodal_spread_levels = [/*cardProps*/ ctx[5]];

	function cardmodal_game_binding(value) {
		/*cardmodal_game_binding*/ ctx[38](value);
	}

	let cardmodal_props = {};

	for (let i = 0; i < cardmodal_spread_levels.length; i += 1) {
		cardmodal_props = assign(cardmodal_props, cardmodal_spread_levels[i]);
	}

	if (/*game*/ ctx[3] !== void 0) {
		cardmodal_props.game = /*game*/ ctx[3];
	}

	cardmodal = new CardModal({ props: cardmodal_props });
	binding_callbacks.push(() => bind(cardmodal, 'game', cardmodal_game_binding));

	function finishedmodal_open_binding(value) {
		/*finishedmodal_open_binding*/ ctx[39](value);
	}

	let finishedmodal_props = {
		compId: /*compId*/ ctx[1],
		gameId: /*gameId*/ ctx[0]
	};

	if (/*finishedModalOpen*/ ctx[7] !== void 0) {
		finishedmodal_props.open = /*finishedModalOpen*/ ctx[7];
	}

	finishedmodal = new FinishedModal({ props: finishedmodal_props });
	binding_callbacks.push(() => bind(finishedmodal, 'open', finishedmodal_open_binding));
	finishedmodal.$on("nextGame", /*onNextGame*/ ctx[14]);

	return {
		c() {
			audio = element("audio");
			t0 = space();
			h5 = element("h5");
			div0 = element("div");
			t1 = text(t1_value);
			t2 = space();
			button0 = element("button");
			span0 = element("span");
			create_component(icon.$$.fragment);
			t3 = space();
			span1 = element("span");
			span1.textContent = "Back";
			t5 = space();
			div11 = element("div");
			div6 = element("div");
			h60 = element("h6");
			span2 = element("span");
			span2.textContent = "Div:";
			t7 = space();
			span3 = element("span");
			t8 = text(t8_value);
			t9 = space();
			div1 = element("div");
			span4 = element("span");
			span4.textContent = "Game#:";
			t11 = space();
			span5 = element("span");
			t12 = text(t12_value);
			t13 = space();
			div2 = element("div");
			span6 = element("span");
			span6.textContent = "Ref1:";
			t15 = space();
			span7 = element("span");
			t16 = text(t16_value);
			t17 = space();
			div3 = element("div");
			span8 = element("span");
			span8.textContent = "Ref2:";
			t19 = space();
			span9 = element("span");
			t20 = text(t20_value);
			t21 = space();
			div4 = element("div");
			span10 = element("span");
			span10.textContent = "Duty:";
			t23 = space();
			span11 = element("span");
			t24 = text(t24_value);
			t25 = space();
			div5 = element("div");
			h61 = element("h6");
			span12 = element("span");
			span12.textContent = "Field#:";
			t27 = space();
			span13 = element("span");
			t28 = text(t28_value);
			t29 = space();
			div8 = element("div");
			div7 = element("div");
			button1 = element("button");
			button1.textContent = "Not started";
			t31 = space();
			button2 = element("button");
			button2.textContent = "1st Half";
			t33 = space();
			button3 = element("button");
			button3.textContent = "2nd Half";
			t35 = space();
			button4 = element("button");
			button4.textContent = "Finished";
			t37 = space();
			div9 = element("div");
			create_component(timer.$$.fragment);
			t38 = space();
			section = element("section");

			for (let i = 0; i < 2; i += 1) {
				each_blocks[i].c();
			}

			t39 = space();
			article = element("article");
			div10 = element("div");
			button5 = element("button");
			button5.textContent = "Undo Last Goal";
			t41 = space();
			button6 = element("button");
			button6.textContent = "Undo Last Card";
			t43 = space();
			create_component(editteammodal.$$.fragment);
			t44 = space();
			create_component(cardmodal.$$.fragment);
			t45 = space();
			create_component(finishedmodal.$$.fragment);
			if (!src_url_equal(audio.src, audio_src_value = hornSound)) attr(audio, "src", audio_src_value);
			attr(audio, "type", "audio/mp3");
			attr(span0, "class", "icon");
			attr(button0, "class", "button is-small is-outlined");
			set_style(button0, "margin-left", "auto");
			attr(h5, "class", "title is-5 is-flex space-between has-background-light");
			set_style(h5, "padding", ".6rem");
			set_style(h5, "margin", "0");
			attr(h60, "class", "title is-6 is-no-wrap is-small");
			set_style(h60, "margin", "0");
			set_style(h60, "padding", "0px");
			attr(span4, "class", "has-text-weight-semibold");
			attr(div1, "class", "is-no-wrap is-small");
			attr(span6, "class", "has-text-weight-semibold");
			attr(div2, "class", "is-no-wrap is-small");
			attr(span8, "class", "has-text-weight-semibold");
			attr(div3, "class", "is-no-wrap is-small");
			attr(span10, "class", "has-text-weight-semibold");
			attr(div4, "class", "is-no-wrap is-small");
			attr(h61, "class", "title is-6 is-small");
			set_style(h61, "margin", "0");
			set_style(h61, "padding", "0px");
			attr(div5, "class", "has-text-right is-no-wrap");
			attr(div6, "class", "is-flex space-between");
			attr(button1, "class", "button is-small");
			toggle_class(button1, "is-dark", /*game*/ ctx[3].gameState === "pending");
			toggle_class(button1, "is-selected", /*game*/ ctx[3].gameState === "pending");
			attr(button2, "class", "button is-small");
			toggle_class(button2, "is-dark", /*game*/ ctx[3].gameState === "first half");
			toggle_class(button2, "is-selected", /*game*/ ctx[3].gameState === "first half");
			attr(button3, "class", "button is-small");
			toggle_class(button3, "is-dark", /*game*/ ctx[3].gameState === "second half");
			toggle_class(button3, "is-selected", /*game*/ ctx[3].gameState === "second half");
			attr(button4, "class", "button is-danger is-small");
			toggle_class(button4, "is-outlined", /*game*/ ctx[3].gameState !== "finished");
			toggle_class(button4, "is-selected", /*game*/ ctx[3].gameState === "finished");
			attr(div7, "class", "buttons has-addons");
			attr(div8, "class", "is-flex space-between");
			set_style(div8, "justify-content", "center");
			set_style(div8, "margin-top", ".5rem");
			attr(div9, "class", "is-flex space-between");
			set_style(div9, "justify-content", "center");
			set_style(div9, "margin-top", ".5rem");
			attr(button5, "class", "button is-danger is-outlined");
			attr(button6, "class", "button is-danger is-outlined");
			attr(div10, "class", "accordion-header has-background-light");
			attr(article, "class", "accordion");
			attr(section, "class", "accordions");
			set_style(section, "margin-top", ".5rem");
			set_style(div11, "padding-left", ".6rem");
			set_style(div11, "padding-right", ".6rem");
		},
		m(target, anchor) {
			insert(target, audio, anchor);
			/*audio_binding*/ ctx[25](audio);
			insert(target, t0, anchor);
			insert(target, h5, anchor);
			append(h5, div0);
			append(div0, t1);
			append(h5, t2);
			append(h5, button0);
			append(button0, span0);
			mount_component(icon, span0, null);
			append(button0, t3);
			append(button0, span1);
			insert(target, t5, anchor);
			insert(target, div11, anchor);
			append(div11, div6);
			append(div6, h60);
			append(h60, span2);
			append(h60, t7);
			append(h60, span3);
			append(span3, t8);
			append(div6, t9);
			append(div6, div1);
			append(div1, span4);
			append(div1, t11);
			append(div1, span5);
			append(span5, t12);
			append(div6, t13);
			append(div6, div2);
			append(div2, span6);
			append(div2, t15);
			append(div2, span7);
			append(span7, t16);
			append(div6, t17);
			append(div6, div3);
			append(div3, span8);
			append(div3, t19);
			append(div3, span9);
			append(span9, t20);
			append(div6, t21);
			append(div6, div4);
			append(div4, span10);
			append(div4, t23);
			append(div4, span11);
			append(span11, t24);
			append(div6, t25);
			append(div6, div5);
			append(div5, h61);
			append(h61, span12);
			append(h61, t27);
			append(h61, span13);
			append(span13, t28);
			append(div11, t29);
			append(div11, div8);
			append(div8, div7);
			append(div7, button1);
			append(div7, t31);
			append(div7, button2);
			append(div7, t33);
			append(div7, button3);
			append(div7, t35);
			append(div7, button4);
			append(div11, t37);
			append(div11, div9);
			mount_component(timer, div9, null);
			append(div11, t38);
			append(div11, section);

			for (let i = 0; i < 2; i += 1) {
				each_blocks[i].m(section, null);
			}

			append(section, t39);
			append(section, article);
			append(article, div10);
			append(div10, button5);
			append(div10, t41);
			append(div10, button6);
			insert(target, t43, anchor);
			mount_component(editteammodal, target, anchor);
			insert(target, t44, anchor);
			mount_component(cardmodal, target, anchor);
			insert(target, t45, anchor);
			mount_component(finishedmodal, target, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[26]),
					listen(button1, "click", /*click_handler_1*/ ctx[27]),
					listen(button2, "click", /*click_handler_2*/ ctx[28]),
					listen(button3, "click", /*click_handler_3*/ ctx[29]),
					listen(button4, "click", /*click_handler_4*/ ctx[30]),
					listen(button5, "click", /*deleteLastGoal*/ ctx[17]),
					listen(button6, "click", /*deleteLastCard*/ ctx[18])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*game*/ 8) && t1_value !== (t1_value = /*game*/ ctx[3].compName + "")) set_data(t1, t1_value);
			if ((!current || dirty[0] & /*game*/ 8) && t8_value !== (t8_value = /*game*/ ctx[3].poolName + "")) set_data(t8, t8_value);
			if ((!current || dirty[0] & /*game*/ 8) && t12_value !== (t12_value = /*game*/ ctx[3].id + "")) set_data(t12, t12_value);
			if ((!current || dirty[0] & /*getTeamName*/ 2048) && t16_value !== (t16_value = /*getTeamName*/ ctx[11]("r1") + "")) set_data(t16, t16_value);
			if ((!current || dirty[0] & /*getTeamName*/ 2048) && t20_value !== (t20_value = /*getTeamName*/ ctx[11]("r2") + "")) set_data(t20, t20_value);
			if ((!current || dirty[0] & /*getTeamName*/ 2048) && t24_value !== (t24_value = /*getTeamName*/ ctx[11]("duty") + "")) set_data(t24, t24_value);
			if ((!current || dirty[0] & /*game*/ 8) && t28_value !== (t28_value = /*game*/ ctx[3].field + "")) set_data(t28, t28_value);

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button1, "is-dark", /*game*/ ctx[3].gameState === "pending");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button1, "is-selected", /*game*/ ctx[3].gameState === "pending");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button2, "is-dark", /*game*/ ctx[3].gameState === "first half");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button2, "is-selected", /*game*/ ctx[3].gameState === "first half");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button3, "is-dark", /*game*/ ctx[3].gameState === "second half");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button3, "is-selected", /*game*/ ctx[3].gameState === "second half");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button4, "is-outlined", /*game*/ ctx[3].gameState !== "finished");
			}

			if (!current || dirty[0] & /*game*/ 8) {
				toggle_class(button4, "is-selected", /*game*/ ctx[3].gameState === "finished");
			}

			const timer_changes = {};
			if (dirty[0] & /*game*/ 8) timer_changes.timeSec = /*game*/ ctx[3].timeSec;
			timer.$set(timer_changes);

			if (dirty[0] & /*accordionOpen, scoreGoal, game, openCardModal, getPlayerCards, getPlayerGoals, setForfeitState, openEditTeamModal, getTeamName*/ 7867928) {
				each_value = ["t1", "t2"];
				let i;

				for (i = 0; i < 2; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(section, t39);
					}
				}

				group_outros();

				for (i = 2; i < 2; i += 1) {
					out(i);
				}

				check_outros();
			}

			const editteammodal_changes = (dirty[0] & /*teamModalProps*/ 64)
			? get_spread_update(editteammodal_spread_levels, [get_spread_object(/*teamModalProps*/ ctx[6])])
			: {};

			editteammodal.$set(editteammodal_changes);

			const cardmodal_changes = (dirty[0] & /*cardProps*/ 32)
			? get_spread_update(cardmodal_spread_levels, [get_spread_object(/*cardProps*/ ctx[5])])
			: {};

			if (!updating_game && dirty[0] & /*game*/ 8) {
				updating_game = true;
				cardmodal_changes.game = /*game*/ ctx[3];
				add_flush_callback(() => updating_game = false);
			}

			cardmodal.$set(cardmodal_changes);
			const finishedmodal_changes = {};
			if (dirty[0] & /*compId*/ 2) finishedmodal_changes.compId = /*compId*/ ctx[1];
			if (dirty[0] & /*gameId*/ 1) finishedmodal_changes.gameId = /*gameId*/ ctx[0];

			if (!updating_open && dirty[0] & /*finishedModalOpen*/ 128) {
				updating_open = true;
				finishedmodal_changes.open = /*finishedModalOpen*/ ctx[7];
				add_flush_callback(() => updating_open = false);
			}

			finishedmodal.$set(finishedmodal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			transition_in(timer.$$.fragment, local);

			for (let i = 0; i < 2; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(editteammodal.$$.fragment, local);
			transition_in(cardmodal.$$.fragment, local);
			transition_in(finishedmodal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			transition_out(timer.$$.fragment, local);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < 2; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(editteammodal.$$.fragment, local);
			transition_out(cardmodal.$$.fragment, local);
			transition_out(finishedmodal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(audio);
			/*audio_binding*/ ctx[25](null);
			if (detaching) detach(t0);
			if (detaching) detach(h5);
			destroy_component(icon);
			if (detaching) detach(t5);
			if (detaching) detach(div11);
			destroy_component(timer);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t43);
			destroy_component(editteammodal, detaching);
			if (detaching) detach(t44);
			destroy_component(cardmodal, detaching);
			if (detaching) detach(t45);
			destroy_component(finishedmodal, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (274:14) {#if game.forfeit === slot}
function create_if_block(ctx) {
	let span;

	return {
		c() {
			span = element("span");
			span.textContent = "Forfeited";
			attr(span, "class", "tag is-warning");
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (279:14) {#each getActiveCards(game, game[slot]) as card}
function create_each_block_3(ctx) {
	let cardbadge;
	let current;

	cardbadge = new CardBadge({
			props: {
				game: /*game*/ ctx[3],
				card: /*card*/ ctx[54],
				slot: /*slot*/ ctx[48]
			}
		});

	return {
		c() {
			create_component(cardbadge.$$.fragment);
		},
		m(target, anchor) {
			mount_component(cardbadge, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const cardbadge_changes = {};
			if (dirty[0] & /*game*/ 8) cardbadge_changes.game = /*game*/ ctx[3];
			if (dirty[0] & /*game*/ 8) cardbadge_changes.card = /*card*/ ctx[54];
			cardbadge.$set(cardbadge_changes);
		},
		i(local) {
			if (current) return;
			transition_in(cardbadge.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cardbadge.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(cardbadge, detaching);
		}
	};
}

// (290:16) <Buger>
function create_default_slot(ctx) {
	let aside;
	let ul;
	let li0;
	let t1;
	let li1;
	let t3;
	let li2;
	let a2;

	let t4_value = (/*game*/ ctx[3].forfeit === "no"
	? "Forfeit team"
	: "No forfeit") + "";

	let t4;
	let mounted;
	let dispose;

	function click_handler_5() {
		return /*click_handler_5*/ ctx[31](/*slot*/ ctx[48]);
	}

	function click_handler_6() {
		return /*click_handler_6*/ ctx[32](/*slot*/ ctx[48]);
	}

	function click_handler_7() {
		return /*click_handler_7*/ ctx[33](/*slot*/ ctx[48]);
	}

	return {
		c() {
			aside = element("aside");
			ul = element("ul");
			li0 = element("li");
			li0.innerHTML = `<a>Card team</a>`;
			t1 = space();
			li1 = element("li");
			li1.innerHTML = `<a>Edit team</a>`;
			t3 = space();
			li2 = element("li");
			a2 = element("a");
			t4 = text(t4_value);
			attr(ul, "class", "menu-list");
			attr(aside, "class", "menu");
		},
		m(target, anchor) {
			insert(target, aside, anchor);
			append(aside, ul);
			append(ul, li0);
			append(ul, t1);
			append(ul, li1);
			append(ul, t3);
			append(ul, li2);
			append(li2, a2);
			append(a2, t4);

			if (!mounted) {
				dispose = [
					listen(li0, "click", click_handler_5),
					listen(li1, "click", click_handler_6),
					listen(li2, "click", click_handler_7)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*game*/ 8 && t4_value !== (t4_value = (/*game*/ ctx[3].forfeit === "no"
			? "Forfeit team"
			: "No forfeit") + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(aside);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (334:22) {#each getPlayerCards(player.uuid) as card}
function create_each_block_2(ctx) {
	let span;
	let t0_value = cardTypeAbbrev[/*card*/ ctx[54].type] + "";
	let t0;
	let t1;
	let span_title_value;
	let span_class_value;

	return {
		c() {
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			attr(span, "title", span_title_value = `${/*card*/ ctx[54].severity}: ${/*card*/ ctx[54].type}`);
			attr(span, "class", span_class_value = "" + (null_to_empty(`tag is-${severityToColor[/*card*/ ctx[54].severity]} is-very-small`) + " svelte-r3qb2h"));
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t0);
			append(span, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*getPlayerCards, game*/ 520 && t0_value !== (t0_value = cardTypeAbbrev[/*card*/ ctx[54].type] + "")) set_data(t0, t0_value);

			if (dirty[0] & /*getPlayerCards, game*/ 520 && span_title_value !== (span_title_value = `${/*card*/ ctx[54].severity}: ${/*card*/ ctx[54].type}`)) {
				attr(span, "title", span_title_value);
			}

			if (dirty[0] & /*getPlayerCards, game*/ 520 && span_class_value !== (span_class_value = "" + (null_to_empty(`tag is-${severityToColor[/*card*/ ctx[54].severity]} is-very-small`) + " svelte-r3qb2h"))) {
				attr(span, "class", span_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (326:16) {#each _get(game.teamsLookup[game[slot]], "players", []) as player}
function create_each_block_1(ctx) {
	let li;
	let h5;
	let t0_value = /*player*/ ctx[51].number + "";
	let t0;
	let t1;
	let div0;
	let t2_value = /*player*/ ctx[51].name + "";
	let t2;
	let t3;
	let div1;
	let span;
	let t4;
	let t5_value = /*getPlayerGoals*/ ctx[10](/*player*/ ctx[51].uuid) + "";
	let t5;
	let t6;
	let t7;
	let div2;
	let button0;
	let t9;
	let button1;
	let mounted;
	let dispose;
	let each_value_2 = /*getPlayerCards*/ ctx[9](/*player*/ ctx[51].uuid);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	function click_handler_10() {
		return /*click_handler_10*/ ctx[35](/*slot*/ ctx[48], /*player*/ ctx[51]);
	}

	function click_handler_11() {
		return /*click_handler_11*/ ctx[36](/*slot*/ ctx[48], /*player*/ ctx[51]);
	}

	return {
		c() {
			li = element("li");
			h5 = element("h5");
			t0 = text(t0_value);
			t1 = space();
			div0 = element("div");
			t2 = text(t2_value);
			t3 = space();
			div1 = element("div");
			span = element("span");
			t4 = text("Goals: ");
			t5 = text(t5_value);
			t6 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t7 = space();
			div2 = element("div");
			button0 = element("button");
			button0.textContent = "Goal";
			t9 = space();
			button1 = element("button");
			button1.textContent = "Card";
			attr(h5, "class", "title is-5 mb-0 mr-1");
			attr(div0, "class", "mr-1 is-capitalized");
			attr(span, "class", "tag is-success is-very-small mb-0");
			attr(div1, "class", "tags mb-0");
			attr(button0, "class", "button is-primary is-outlined is-small mr-1");
			attr(button1, "class", "button is-warning is-outlined is-small");
			attr(div2, "class", "is-flex ml-auto");
			attr(li, "class", "is-flex list-item svelte-r3qb2h");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, h5);
			append(h5, t0);
			append(li, t1);
			append(li, div0);
			append(div0, t2);
			append(li, t3);
			append(li, div1);
			append(div1, span);
			append(span, t4);
			append(span, t5);
			append(div1, t6);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			append(li, t7);
			append(li, div2);
			append(div2, button0);
			append(div2, t9);
			append(div2, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", click_handler_10),
					listen(button1, "click", click_handler_11)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*game*/ 8 && t0_value !== (t0_value = /*player*/ ctx[51].number + "")) set_data(t0, t0_value);
			if (dirty[0] & /*game*/ 8 && t2_value !== (t2_value = /*player*/ ctx[51].name + "")) set_data(t2, t2_value);
			if (dirty[0] & /*getPlayerGoals, game*/ 1032 && t5_value !== (t5_value = /*getPlayerGoals*/ ctx[10](/*player*/ ctx[51].uuid) + "")) set_data(t5, t5_value);

			if (dirty[0] & /*getPlayerCards, game*/ 520) {
				each_value_2 = /*getPlayerCards*/ ctx[9](/*player*/ ctx[51].uuid);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d(detaching) {
			if (detaching) detach(li);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (262:6) {#each ["t1", "t2"] as slot}
function create_each_block(ctx) {
	let article;
	let div3;
	let p0;
	let span0;
	let icon;
	let t0;
	let span1;
	let t1_value = (/*slot*/ ctx[48] === "t1" ? "Team1:" : "Team2:") + "";
	let t1;
	let t2;
	let span2;
	let t3_value = /*getTeamName*/ ctx[11](/*slot*/ ctx[48]) + "";
	let t3;
	let t4;
	let t5;
	let p1;
	let t6;
	let p2;
	let t7;
	let div2;
	let div0;
	let t8;
	let span3;
	let t9_value = goalsForSlot(/*game*/ ctx[3], /*slot*/ ctx[48]) + "";
	let t9;
	let t10;
	let div1;
	let buger;
	let t11;
	let div7;
	let div6;
	let ul;
	let t12;
	let li;
	let div4;
	let t14;
	let div5;
	let button;
	let current;
	let mounted;
	let dispose;

	icon = new Icon({
			props: {
				class: "",
				icon: /*accordionOpen*/ ctx[4][/*slot*/ ctx[48]]
				? faAngleDown
				: faAngleRight
			}
		});

	let if_block = /*game*/ ctx[3].forfeit === /*slot*/ ctx[48] && create_if_block(ctx);
	let each_value_3 = getActiveCards(/*game*/ ctx[3], /*game*/ ctx[3][/*slot*/ ctx[48]]);
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks_1[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	const out = i => transition_out(each_blocks_1[i], 1, 1, () => {
		each_blocks_1[i] = null;
	});

	buger = new Buger({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	function click_handler_9() {
		return /*click_handler_9*/ ctx[34](/*slot*/ ctx[48]);
	}

	let each_value_1 = _get(/*game*/ ctx[3].teamsLookup[/*game*/ ctx[3][/*slot*/ ctx[48]]], "players", []);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	function click_handler_12() {
		return /*click_handler_12*/ ctx[37](/*slot*/ ctx[48]);
	}

	return {
		c() {
			article = element("article");
			div3 = element("div");
			p0 = element("p");
			span0 = element("span");
			create_component(icon.$$.fragment);
			t0 = space();
			span1 = element("span");
			t1 = text(t1_value);
			t2 = space();
			span2 = element("span");
			t3 = text(t3_value);
			t4 = space();
			if (if_block) if_block.c();
			t5 = space();
			p1 = element("p");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t6 = space();
			p2 = element("p");
			t7 = space();
			div2 = element("div");
			div0 = element("div");
			t8 = text("Goals:\n                ");
			span3 = element("span");
			t9 = text(t9_value);
			t10 = space();
			div1 = element("div");
			create_component(buger.$$.fragment);
			t11 = space();
			div7 = element("div");
			div6 = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t12 = space();
			li = element("li");
			div4 = element("div");
			div4.textContent = "fill in";
			t14 = space();
			div5 = element("div");
			button = element("button");
			button.textContent = "Goal";
			attr(span0, "class", "icon");
			attr(span1, "class", "mr-1");
			attr(span2, "class", "has-text-weight-bold is-capitalized");
			attr(p0, "class", "is-flex");
			attr(p1, "class", "tags");
			attr(p2, "class", "tags");
			attr(span3, "class", "title is-6");
			attr(div0, "class", "mr-1");
			attr(div2, "class", "is-flex");
			attr(div3, "class", "accordion-header toggle has-background-light");
			attr(div4, "class", "mr-1 is-capitalized");
			attr(button, "class", "button is-primary is-outlined is-small mr-1");
			attr(div5, "class", "is-flex ml-auto");
			attr(li, "class", "is-flex list-item svelte-r3qb2h");
			attr(div6, "class", "accordion-content");
			set_style(div6, "padding", "0");
			attr(div7, "class", "accordion-body");
			attr(article, "class", "accordion");
			toggle_class(article, "is-active", /*accordionOpen*/ ctx[4][/*slot*/ ctx[48]]);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div3);
			append(div3, p0);
			append(p0, span0);
			mount_component(icon, span0, null);
			append(p0, t0);
			append(p0, span1);
			append(span1, t1);
			append(p0, t2);
			append(p0, span2);
			append(span2, t3);
			append(p0, t4);
			if (if_block) if_block.m(p0, null);
			append(div3, t5);
			append(div3, p1);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(p1, null);
			}

			append(div3, t6);
			append(div3, p2);
			append(div3, t7);
			append(div3, div2);
			append(div2, div0);
			append(div0, t8);
			append(div0, span3);
			append(span3, t9);
			append(div2, t10);
			append(div2, div1);
			mount_component(buger, div1, null);
			append(article, t11);
			append(article, div7);
			append(div7, div6);
			append(div6, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			append(ul, t12);
			append(ul, li);
			append(li, div4);
			append(li, t14);
			append(li, div5);
			append(div5, button);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div1, "click", stop_propagation(click_handler_8)),
					listen(div3, "click", click_handler_9),
					listen(button, "click", click_handler_12)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const icon_changes = {};

			if (dirty[0] & /*accordionOpen*/ 16) icon_changes.icon = /*accordionOpen*/ ctx[4][/*slot*/ ctx[48]]
			? faAngleDown
			: faAngleRight;

			icon.$set(icon_changes);
			if ((!current || dirty[0] & /*getTeamName*/ 2048) && t3_value !== (t3_value = /*getTeamName*/ ctx[11](/*slot*/ ctx[48]) + "")) set_data(t3, t3_value);

			if (/*game*/ ctx[3].forfeit === /*slot*/ ctx[48]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(p0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*game*/ 8) {
				each_value_3 = getActiveCards(/*game*/ ctx[3], /*game*/ ctx[3][/*slot*/ ctx[48]]);
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_3(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(p1, null);
					}
				}

				group_outros();

				for (i = each_value_3.length; i < each_blocks_1.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if ((!current || dirty[0] & /*game*/ 8) && t9_value !== (t9_value = goalsForSlot(/*game*/ ctx[3], /*slot*/ ctx[48]) + "")) set_data(t9, t9_value);
			const buger_changes = {};

			if (dirty[0] & /*game*/ 8 | dirty[1] & /*$$scope*/ 268435456) {
				buger_changes.$$scope = { dirty, ctx };
			}

			buger.$set(buger_changes);

			if (dirty[0] & /*openCardModal, game, scoreGoal, getPlayerCards, getPlayerGoals*/ 4720136) {
				each_value_1 = _get(/*game*/ ctx[3].teamsLookup[/*game*/ ctx[3][/*slot*/ ctx[48]]], "players", []);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, t12);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (!current || dirty[0] & /*accordionOpen*/ 16) {
				toggle_class(article, "is-active", /*accordionOpen*/ ctx[4][/*slot*/ ctx[48]]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);

			for (let i = 0; i < each_value_3.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			transition_in(buger.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			each_blocks_1 = each_blocks_1.filter(Boolean);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			transition_out(buger.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(article);
			destroy_component(icon);
			if (if_block) if_block.d();
			destroy_each(each_blocks_1, detaching);
			destroy_component(buger);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (167:20)    <Loading /> {:then g}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let passwordmodal;
	let t;
	let await_block_anchor;
	let promise;
	let current;

	passwordmodal = new PasswordModal({
			props: {
				open: /*showPasswordModal*/ ctx[8],
				compId: /*compId*/ ctx[1]
			}
		});

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 47,
		blocks: [,,,]
	};

	handle_promise(promise = /*gamePromise*/ ctx[12], info);

	return {
		c() {
			create_component(passwordmodal.$$.fragment);
			t = space();
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			mount_component(passwordmodal, target, anchor);
			insert(target, t, anchor);
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const passwordmodal_changes = {};
			if (dirty[0] & /*showPasswordModal*/ 256) passwordmodal_changes.open = /*showPasswordModal*/ ctx[8];
			if (dirty[0] & /*compId*/ 2) passwordmodal_changes.compId = /*compId*/ ctx[1];
			passwordmodal.$set(passwordmodal_changes);
			update_await_block_branch(info, ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(passwordmodal.$$.fragment, local);
			transition_in(info.block);
			current = true;
		},
		o(local) {
			transition_out(passwordmodal.$$.fragment, local);

			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			destroy_component(passwordmodal, detaching);
			if (detaching) detach(t);
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

const click_handler_8 = () => {
	
};

function instance($$self, $$props, $$invalidate) {
	let playSound;
	let game;
	let getTeamName;
	let getPlayerGoals;
	let getPlayerCards;
	let $hideNavBar;
	let $resetTimer;
	component_subscribe($$self, hideNavBar, $$value => $$invalidate(42, $hideNavBar = $$value));
	component_subscribe($$self, resetTimer, $$value => $$invalidate(43, $resetTimer = $$value));
	let { compId } = $$props;
	let { gameId } = $$props;
	let showFinishedModal = false;
	let accordionOpen = { t1: false, t2: false };
	let cardProps = { open: false };
	let teamModalProps = { open: false, teamUuid: undefined, compId };
	let finishedModalOpen = false;
	const getGamePath = () => `comps/${compId}/games/${gameId}`;
	let horn;
	let showPasswordModal = false;

	const getGame = () => get(getGamePath()).then(g => {
		$$invalidate(3, game = g);
		$$invalidate(3, game.timeSec = game.timeSec || 10 * 60, game);
		return g;
	});

	let gamePromise = getGame();
	const onTeamUpdated = () => getGame();

	const onNextGame = e => {
		$$invalidate(3, game = e.detail);
		$$invalidate(0, gameId = e.detail.id);
	};

	const onStateClick = async state => {
		setState(state);

		// if the user manually changes the state reset the timer.
		set_store_value(resetTimer, $resetTimer = { state }, $resetTimer);
	};

	const setState = async state => {
		console.log("set state", state, game.gameState);

		if (state !== game.gameState) {
			$$invalidate(3, game = await post(getGamePath() + "/state", { state }));
		}

		if (state === "finished") {
			$$invalidate(7, finishedModalOpen = true);
		}
	};

	const setGameTime = ticEvent => {
		const timeSec = ticEvent.detail;
		$$invalidate(3, game.timeSec = timeSec, game);
		post(getGamePath() + "/time", { timeSec });
	};

	const deleteLastGoal = async ({ teamUuid, playerUuid } = {}) => {
		const matchingGoals = game.goals.filter(goal => (teamUuid ? goal.team === teamUuid : true) && (playerUuid ? goal.player === playerUuid : true));
		matchingGoals.sort((g1, g2) => g2.id - g1.id);

		if (matchingGoals.length > 0) {
			$$invalidate(3, game = await del(getGamePath() + `/goals/${matchingGoals[0].id}`));
		} else {
			console.log("no matching goals.");
		}
	};

	const deleteLastCard = async ({ teamUuid, playerUuid } = {}) => {
		const matchingCards = game.cards.filter(goal => (teamUuid ? goal.team === teamUuid : true) && (playerUuid ? goal.player === playerUuid : true));
		matchingCards.sort((g1, g2) => g2.id - g1.id);

		if (matchingCards.length > 0) {
			$$invalidate(3, game = await del(getGamePath() + `/cards/${matchingCards[0].id}`));
		} else {
			console.log("no matching cards.");
		}
	};

	const scoreGoal = async (slot, playerUuid) => {
		$$invalidate(3, game = await post(getGamePath() + "/goals", {
			teamUuid: game[slot],
			playerUuid,
			timeRemainingSec: game.timeSec
		}));

		$$invalidate(4, accordionOpen[slot] = false, accordionOpen);
	};

	const setForfeitState = async slot => {
		let forfeit = slot;

		if (game.forfeit === forfeit) {
			forfeit = "no"; // toggle it back off.
		}

		$$invalidate(3, game = await post(getGamePath() + "/forfeit", { forfeit }));
	};

	const openEditTeamModal = teamUuid => {
		$$invalidate(6, teamModalProps = { open: true, teamUuid, compId });
	};

	const openCardModal = (teamUuid, player) => {
		$$invalidate(5, cardProps = {
			team: game.teamsLookup[teamUuid],
			player,
			open: true
		});
	};

	const onTimerFinished = () => {
		playSound();

		if (game.gameState === "first half") {
			setState("second half");
		} else if (game.gameState === "second half") {
			showFinishedModal = true;
		}
	};

	const onTimerStarted = () => {
		if (game.gameState === "pending") {
			setState("first half");
		}
	};

	onMount(async () => {
		set_store_value(hideNavBar, $hideNavBar = true, $hideNavBar);
		$$invalidate(8, showPasswordModal = true);
	});

	onDestroy(() => {
		set_store_value(hideNavBar, $hideNavBar = false, $hideNavBar);
	});

	function audio_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			horn = $$value;
			$$invalidate(2, horn);
		});
	}

	const click_handler = () => backToComp(compId);
	const click_handler_1 = () => onStateClick("pending");
	const click_handler_2 = () => onStateClick("first half");
	const click_handler_3 = () => onStateClick("second half");
	const click_handler_4 = () => onStateClick("finished");

	const click_handler_5 = slot => {
		openCardModal(game[slot]);
	};

	const click_handler_6 = slot => {
		openEditTeamModal(game[slot]);
	};

	const click_handler_7 = slot => {
		setForfeitState(slot);
	};

	const click_handler_9 = slot => $$invalidate(4, accordionOpen[slot] = !accordionOpen[slot], accordionOpen);
	const click_handler_10 = (slot, player) => scoreGoal(slot, player.uuid);
	const click_handler_11 = (slot, player) => openCardModal(game[slot], player);
	const click_handler_12 = slot => scoreGoal(slot);

	function cardmodal_game_binding(value) {
		game = value;
		$$invalidate(3, game);
	}

	function finishedmodal_open_binding(value) {
		finishedModalOpen = value;
		$$invalidate(7, finishedModalOpen);
	}

	$$self.$$set = $$props => {
		if ('compId' in $$props) $$invalidate(1, compId = $$props.compId);
		if ('gameId' in $$props) $$invalidate(0, gameId = $$props.gameId);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*horn*/ 4) {
			$: playSound = () => {
				try {
					horn.play();
				} catch(e) {
					console.log(e);
				}
			};
		}

		if ($$self.$$.dirty[0] & /*game*/ 8) {
			$: $$invalidate(11, getTeamName = slot => {
				const team = game.teamsLookup[game[slot]];

				if (team) {
					return team.name;
				}

				return game[`${slot}Placeholder`];
			});
		}

		if ($$self.$$.dirty[0] & /*game*/ 8) {
			$: $$invalidate(10, getPlayerGoals = playerUuid => game.goals.filter(goal => goal.player === playerUuid).length);
		}

		if ($$self.$$.dirty[0] & /*game*/ 8) {
			$: $$invalidate(9, getPlayerCards = playerUuid => game.cards.filter(card => card.player === playerUuid));
		}
	};

	$: $$invalidate(3, game = {});

	return [
		gameId,
		compId,
		horn,
		game,
		accordionOpen,
		cardProps,
		teamModalProps,
		finishedModalOpen,
		showPasswordModal,
		getPlayerCards,
		getPlayerGoals,
		getTeamName,
		gamePromise,
		onTeamUpdated,
		onNextGame,
		onStateClick,
		setGameTime,
		deleteLastGoal,
		deleteLastCard,
		scoreGoal,
		setForfeitState,
		openEditTeamModal,
		openCardModal,
		onTimerFinished,
		onTimerStarted,
		audio_binding,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		click_handler_7,
		click_handler_9,
		click_handler_10,
		click_handler_11,
		click_handler_12,
		cardmodal_game_binding,
		finishedmodal_open_binding
	];
}

class Duty extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { compId: 1, gameId: 0 }, null, [-1, -1]);
	}
}

export default Duty;