/* routes/Create.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import PageTitle from "../components/PageTitle.svelte";
import Wizard from "../components/Wizard.svelte";
import Teams from "../components/CreatePages/Teams.svelte";
import Pools from "../components/CreatePages/Pools.svelte";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Build Your Comp");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (25:2) 
function create_page1_slot(ctx) {
	let div;
	let teams_1;
	let current;
	let teams_1_props = { teams: /*teams*/ ctx[4] };
	teams_1 = new Teams({ props: teams_1_props });
	/*teams_1_binding*/ ctx[8](teams_1);

	return {
		c() {
			div = element("div");
			create_component(teams_1.$$.fragment);
			attr(div, "slot", "page1");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(teams_1, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const teams_1_changes = {};
			teams_1.$set(teams_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(teams_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(teams_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			/*teams_1_binding*/ ctx[8](null);
			destroy_component(teams_1);
		}
	};
}

// (29:2) 
function create_page2_slot(ctx) {
	let div;
	let pools_1;
	let current;

	let pools_1_props = {
		pools: /*pools*/ ctx[5],
		teams: /*teams*/ ctx[4]
	};

	pools_1 = new Pools({ props: pools_1_props });
	/*pools_1_binding*/ ctx[7](pools_1);

	return {
		c() {
			div = element("div");
			create_component(pools_1.$$.fragment);
			attr(div, "slot", "page2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(pools_1, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const pools_1_changes = {};
			pools_1.$set(pools_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pools_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pools_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			/*pools_1_binding*/ ctx[7](null);
			destroy_component(pools_1);
		}
	};
}

function create_fragment(ctx) {
	let pagetitle;
	let t0;
	let br;
	let t1;
	let p;
	let t3;
	let wizard;
	let current;

	pagetitle = new PageTitle({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	wizard = new Wizard({
			props: {
				pages: /*pages*/ ctx[3],
				currentPage: /*currentPage*/ ctx[0],
				$$slots: {
					page2: [create_page2_slot],
					page1: [create_page1_slot]
				},
				$$scope: { ctx }
			}
		});

	wizard.$on("next", /*nextPage*/ ctx[6]);

	return {
		c() {
			create_component(pagetitle.$$.fragment);
			t0 = space();
			br = element("br");
			t1 = space();
			p = element("p");
			p.textContent = "Work in progress! Use the Publish Page directly for now.";
			t3 = space();
			create_component(wizard.$$.fragment);
			attr(p, "class", "pb-3");
		},
		m(target, anchor) {
			mount_component(pagetitle, target, anchor);
			insert(target, t0, anchor);
			insert(target, br, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
			insert(target, t3, anchor);
			mount_component(wizard, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const pagetitle_changes = {};

			if (dirty & /*$$scope*/ 512) {
				pagetitle_changes.$$scope = { dirty, ctx };
			}

			pagetitle.$set(pagetitle_changes);
			const wizard_changes = {};
			if (dirty & /*currentPage*/ 1) wizard_changes.currentPage = /*currentPage*/ ctx[0];

			if (dirty & /*$$scope, poolsPage, teamsPage*/ 518) {
				wizard_changes.$$scope = { dirty, ctx };
			}

			wizard.$set(wizard_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pagetitle.$$.fragment, local);
			transition_in(wizard.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pagetitle.$$.fragment, local);
			transition_out(wizard.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(pagetitle, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(br);
			if (detaching) detach(t1);
			if (detaching) detach(p);
			if (detaching) detach(t3);
			destroy_component(wizard, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let pages = ["Enter teams", "Configure Pools/Divisions", "Order Games", "Finish"];
	let currentPage = "Enter teams";
	let teams = [];
	let pools = [];
	let teamsPage = {};
	let poolsPage = {};

	const nextPage = () => {
		$$invalidate(0, currentPage = pages[pages.indexOf(currentPage) + 1]);
	};

	function pools_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			poolsPage = $$value;
			$$invalidate(2, poolsPage);
		});
	}

	function teams_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			teamsPage = $$value;
			$$invalidate(1, teamsPage);
		});
	}

	return [
		currentPage,
		teamsPage,
		poolsPage,
		pages,
		teams,
		pools,
		nextPage,
		pools_1_binding,
		teams_1_binding
	];
}

class Create extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Create;