/* components/CreatePages/Pool.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[1] = list[i];
	return child_ctx;
}

// (9:6) {#each pool.teams as team}
function create_each_block(ctx) {
	let span;
	let t_value = /*team*/ ctx[1] + "";
	let t;

	return {
		c() {
			span = element("span");
			t = text(t_value);
			attr(span, "class", "tag mb-1");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*pool*/ 1 && t_value !== (t_value = /*team*/ ctx[1] + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let t0_value = /*pool*/ ctx[0].name + "";
	let t0;
	let t1;
	let div1;
	let lu;
	let each_value = /*pool*/ ctx[0].teams;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			lu = element("lu");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "panel-heading");
			attr(div1, "class", "panel-block");
			attr(div2, "class", "panel is-narrow");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t0);
			append(div2, t1);
			append(div2, div1);
			append(div1, lu);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(lu, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*pool*/ 1 && t0_value !== (t0_value = /*pool*/ ctx[0].name + "")) set_data(t0, t0_value);

			if (dirty & /*pool*/ 1) {
				each_value = /*pool*/ ctx[0].teams;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(lu, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { pool } = $$props;

	$$self.$$set = $$props => {
		if ('pool' in $$props) $$invalidate(0, pool = $$props.pool);
	};

	return [pool];
}

class Pool extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { pool: 0 });
	}
}

export default Pool;