/* routes/404.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_style,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Error from "../components/Error.svelte";
import { onMount } from "svelte";
import { navigate } from "svelte-routing";

function create_fragment(ctx) {
	let div;
	let h2;
	let t1;
	let error;
	let current;

	error = new Error({
			props: { message: "Oops you shouldn't be here..." }
		});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			h2.textContent = "404";
			t1 = space();
			create_component(error.$$.fragment);
			attr(h2, "class", "title is-2 has-text-danger");
			set_style(div, "display", "flex");
			set_style(div, "flex-direction", "column");
			set_style(div, "align-items", "center");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(div, t1);
			mount_component(error, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(error.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(error);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { location } = $$props;

	onMount(() => {
		console.log("location", location);

		if (location.pathname === "/") {
			navigate("comps", { replace: true });
		}
	});

	$$self.$$set = $$props => {
		if ('location' in $$props) $$invalidate(0, location = $$props.location);
	};

	return [location];
}

class _404 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { location: 0 });
	}
}

export default _404;