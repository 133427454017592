/* components/CreatePages/Teams.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_style,
	space,
	text
} from "svelte/internal";

import { isSameName } from "../../utils/createCompUtils";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	child_ctx[9] = i;
	return child_ctx;
}

// (43:4) {#if teamError}
function create_if_block(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*teamError*/ ctx[2]);
			attr(div, "class", "help");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*teamError*/ 4) set_data(t, /*teamError*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (49:6) {#each teams as team, index}
function create_each_block(ctx) {
	let li;
	let span;
	let t0_value = /*team*/ ctx[7] + "";
	let t0;
	let t1;
	let button;
	let t2;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[6](/*index*/ ctx[9]);
	}

	return {
		c() {
			li = element("li");
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			button = element("button");
			t2 = space();
			attr(button, "class", "delete is-small");
			attr(span, "class", "tag mb-1");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, span);
			append(span, t0);
			append(span, t1);
			append(span, button);
			append(li, t2);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*teams*/ 1 && t0_value !== (t0_value = /*team*/ ctx[7] + "")) set_data(t0, t0_value);
		},
		d(detaching) {
			if (detaching) detach(li);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let div1;
	let label;
	let t1;
	let div0;
	let input;
	let t2;
	let t3;
	let div2;
	let ol;
	let mounted;
	let dispose;
	let if_block = /*teamError*/ ctx[2] && create_if_block(ctx);
	let each_value = /*teams*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div3 = element("div");
			div1 = element("div");
			label = element("label");
			label.innerHTML = `<small><i>Press enter to add each team.</i></small>`;
			t1 = space();
			div0 = element("div");
			input = element("input");
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();
			div2 = element("div");
			ol = element("ol");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(label, "class", "label");
			attr(label, "for", "teamInput");
			attr(input, "class", "input");
			attr(input, "name", "teamInput");
			attr(input, "type", "text");
			attr(input, "placeholder", "Enter team name");
			attr(div0, "class", "control");
			set_style(div0, "max-width", "20rem");
			attr(div1, "class", "field column");
			attr(div2, "class", "column");
			attr(div3, "class", "columns");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div1);
			append(div1, label);
			append(div1, t1);
			append(div1, div0);
			append(div0, input);
			set_input_value(input, /*teamName*/ ctx[1]);
			append(div1, t2);
			if (if_block) if_block.m(div1, null);
			append(div3, t3);
			append(div3, div2);
			append(div2, ol);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ol, null);
			}

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[4]),
					listen(input, "keypress", /*keypress_handler*/ ctx[5])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*teamName*/ 2 && input.value !== /*teamName*/ ctx[1]) {
				set_input_value(input, /*teamName*/ ctx[1]);
			}

			if (/*teamError*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*teams*/ 1) {
				each_value = /*teams*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ol, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { teams = [] } = $$props;
	let teamName = "";
	let teamError;

	const addTeam = event => {
		if (event.key !== "Enter") {
			return;
		}

		if (teamName.trim() === "") {
			$$invalidate(2, teamError = "Team name can't be blank.");
			return;
		}

		if (teams.find(t => isSameName(teamName, t)) !== undefined) {
			$$invalidate(2, teamError = "Duplicate team. Every team must have a unique name.");
		} else {
			$$invalidate(2, teamError = undefined);
			$$invalidate(0, teams = [...teams, teamName.trim()]);
			$$invalidate(1, teamName = "");
		}
	};

	function input_input_handler() {
		teamName = this.value;
		$$invalidate(1, teamName);
	}

	const keypress_handler = e => addTeam(e);

	const click_handler = index => {
		teams.splice(index, 1);
		$$invalidate(0, teams = [...teams]);
	};

	$$self.$$set = $$props => {
		if ('teams' in $$props) $$invalidate(0, teams = $$props.teams);
	};

	return [
		teams,
		teamName,
		teamError,
		addTeam,
		input_input_handler,
		keypress_handler,
		click_handler
	];
}

class Teams extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { teams: 0 });
	}
}

export default Teams;