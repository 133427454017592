/* routes/Publish.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { Link, navigate } from "svelte-routing";
import Icon from "fa-svelte";
import PageTitle from "../components/PageTitle.svelte";
import { toast } from "../store/store";
import { post } from "../utils/api";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[23] = list[i];
	return child_ctx;
}

// (56:0) <PageTitle>
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("Publish Comp");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (58:51) <Link to="help">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Wait What!?");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (92:8) {#each timezones as tz}
function create_each_block(ctx) {
	let option;
	let t0_value = /*tz*/ ctx[23] + "";
	let t0;
	let t1;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t0 = text(t0_value);
			t1 = space();
			option.__value = option_value_value = /*tz*/ ctx[23];
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t0);
			append(option, t1);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

// (126:10) {#if teamsFiles}
function create_if_block_1(ctx) {
	let span;
	let t_value = /*teamsFiles*/ ctx[5][0].name + "";
	let t;

	return {
		c() {
			span = element("span");
			t = text(t_value);
			attr(span, "class", "file-name");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*teamsFiles*/ 32 && t_value !== (t_value = /*teamsFiles*/ ctx[5][0].name + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (164:10) {#if drawFiles}
function create_if_block(ctx) {
	let span;
	let t_value = /*drawFiles*/ ctx[6][0].name + "";
	let t;

	return {
		c() {
			span = element("span");
			t = text(t_value);
			attr(span, "class", "file-name");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*drawFiles*/ 64 && t_value !== (t_value = /*drawFiles*/ ctx[6][0].name + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let pagetitle;
	let t0;
	let p0;
	let t2;
	let p1;
	let t3;
	let link;
	let t4;
	let hr;
	let t5;
	let div7;
	let div1;
	let label0;
	let t8;
	let div0;
	let input0;
	let t9;
	let div3;
	let label1;
	let t11;
	let div2;
	let input1;
	let t12;
	let div6;
	let label2;
	let t14;
	let div4;
	let select;
	let t15;
	let div5;
	let t17;
	let div16;
	let div11;
	let label3;
	let t20;
	let div9;
	let div8;
	let label4;
	let input2;
	let t21;
	let span4;
	let span2;
	let icon0;
	let t22;
	let span3;
	let t24;
	let t25;
	let div10;
	let t37;
	let div15;
	let label5;
	let t40;
	let div13;
	let div12;
	let label6;
	let input3;
	let t41;
	let span8;
	let span6;
	let icon1;
	let t42;
	let span7;
	let t44;
	let t45;
	let div14;
	let t57;
	let div25;
	let div20;
	let label7;
	let t60;
	let div17;
	let input4;
	let t61;
	let div19;
	let t64;
	let div24;
	let label8;
	let t66;
	let div21;
	let input5;
	let t67;
	let div23;
	let t70;
	let div27;
	let div26;
	let button;
	let current;
	let mounted;
	let dispose;

	pagetitle = new PageTitle({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	link = new Link({
			props: {
				to: "help",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	let each_value = /*timezones*/ ctx[9];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	icon0 = new Icon({ props: { class: "", icon: faUpload } });
	let if_block0 = /*teamsFiles*/ ctx[5] && create_if_block_1(ctx);
	icon1 = new Icon({ props: { class: "", icon: faUpload } });
	let if_block1 = /*drawFiles*/ ctx[6] && create_if_block(ctx);

	return {
		c() {
			create_component(pagetitle.$$.fragment);
			t0 = space();
			p0 = element("p");
			p0.textContent = "Given the teams.csv, draw.csv and some other info this form allows you to publish your comp.";
			t2 = space();
			p1 = element("p");
			t3 = text("The format for each of the csv's must be exact! ");
			create_component(link.$$.fragment);
			t4 = space();
			hr = element("hr");
			t5 = space();
			div7 = element("div");
			div1 = element("div");
			label0 = element("label");

			label0.innerHTML = `Name
      <span class="has-text-danger">*</span>`;

			t8 = space();
			div0 = element("div");
			input0 = element("input");
			t9 = space();
			div3 = element("div");
			label1 = element("label");
			label1.textContent = "Address";
			t11 = space();
			div2 = element("div");
			input1 = element("input");
			t12 = space();
			div6 = element("div");
			label2 = element("label");
			label2.textContent = "Timezone";
			t14 = space();
			div4 = element("div");
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t15 = space();
			div5 = element("div");
			div5.textContent = "The competition timezone";
			t17 = space();
			div16 = element("div");
			div11 = element("div");
			label3 = element("label");

			label3.innerHTML = `Teams
      <span class="has-text-danger">*</span>`;

			t20 = space();
			div9 = element("div");
			div8 = element("div");
			label4 = element("label");
			input2 = element("input");
			t21 = space();
			span4 = element("span");
			span2 = element("span");
			create_component(icon0.$$.fragment);
			t22 = space();
			span3 = element("span");
			span3.textContent = "Upload Teams (.csv)";
			t24 = space();
			if (if_block0) if_block0.c();
			t25 = space();
			div10 = element("div");

			div10.innerHTML = `<p>The teams csv has the following columns;</p> 
      <p>division,Team Name,Player Name1,Player2,,Player4</p> 
      <p>e.g.</p> 
      <p>A,Chaos,Tom,Sam,,John</p> 
      <p>B,Dolphins,James,Luke,Will,Jamie,...</p> 
      <p>...</p>`;

			t37 = space();
			div15 = element("div");
			label5 = element("label");

			label5.innerHTML = `Draw
      <span class="has-text-danger">*</span>`;

			t40 = space();
			div13 = element("div");
			div12 = element("div");
			label6 = element("label");
			input3 = element("input");
			t41 = space();
			span8 = element("span");
			span6 = element("span");
			create_component(icon1.$$.fragment);
			t42 = space();
			span7 = element("span");
			span7.textContent = "Upload Draw (.csv)";
			t44 = space();
			if (if_block1) if_block1.c();
			t45 = space();
			div14 = element("div");

			div14.innerHTML = `<p>The draw csv has the following columns;</p> 
      <p>dateTime,field#,Division,Team1,Team2,RefTeam1,RefTeam2,DutyTeam</p> 
      <p>e.g.</p> 
      <p>2018-07-24 8:38pm,1,A,Knights,Mooners,Chiefs,RKC,RKC</p> 
      <p>2018-07-24 8:58pm,1,B,Templestowe,Explorers,Dolphins,RKC,RKC</p> 
      <p>...</p>`;

			t57 = space();
			div25 = element("div");
			div20 = element("div");
			label7 = element("label");

			label7.innerHTML = `Duty Password
      <span class="has-text-danger">*</span>`;

			t60 = space();
			div17 = element("div");
			input4 = element("input");
			t61 = space();
			div19 = element("div");

			div19.innerHTML = `Duty teams will be required to enter this password before they can enter the duty screen.
      <div>*Saved in clear text*</div>`;

			t64 = space();
			div24 = element("div");
			label8 = element("label");
			label8.textContent = "Admin Password";
			t66 = space();
			div21 = element("div");
			input5 = element("input");
			t67 = space();
			div23 = element("div");

			div23.innerHTML = `This password will be required for admin functions. Unfortunatly there aren&#39;t many developed
      as yet...
      <div>*Saved in clear text*</div>`;

			t70 = space();
			div27 = element("div");
			div26 = element("div");
			button = element("button");
			button.textContent = "Publish";
			attr(label0, "class", "label");
			attr(label0, "for", "name");
			attr(input0, "name", "name");
			attr(input0, "class", "input");
			attr(input0, "type", "text");
			attr(input0, "placeholder", "The name of the competition");
			attr(div0, "class", "control");
			attr(div1, "class", "field column");
			attr(label1, "class", "label");
			attr(label1, "for", "address");
			attr(input1, "name", "address");
			attr(input1, "class", "input");
			attr(input1, "type", "text");
			attr(input1, "placeholder", "The address of the competition");
			attr(div2, "class", "control");
			attr(div3, "class", "field column");
			attr(label2, "class", "label");
			attr(label2, "for", "timezone");
			attr(select, "name", "timezone");
			if (/*timezone*/ ctx[4] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[14].call(select));
			attr(div4, "class", "select");
			attr(div5, "class", "help");
			attr(div6, "class", "field column");
			attr(div7, "class", "columns");
			attr(label3, "class", "label");
			attr(label3, "for", "teamsInput");
			attr(input2, "name", "teamsInput");
			attr(input2, "class", "file-input");
			attr(input2, "type", "file");
			attr(span2, "class", "file-icon");
			attr(span3, "class", "file-label");
			attr(span4, "class", "file-cta");
			attr(label4, "class", "file-label");
			attr(div8, "class", "file has-name");
			attr(div9, "class", "control");
			attr(div10, "class", "help");
			attr(div11, "class", "field column");
			attr(label5, "class", "label");
			attr(label5, "for", "drawInput");
			attr(input3, "class", "file-input");
			attr(input3, "type", "file");
			attr(input3, "name", "drawInput");
			attr(span6, "class", "file-icon");
			attr(span7, "class", "file-label");
			attr(span8, "class", "file-cta");
			attr(label6, "class", "file-label");
			attr(div12, "class", "file has-name");
			attr(div13, "class", "control");
			attr(div14, "class", "help");
			attr(div15, "class", "field column");
			attr(div16, "class", "columns");
			attr(label7, "class", "label");
			attr(label7, "for", "dutyPW");
			attr(input4, "class", "input");
			attr(input4, "name", "dutyPW");
			attr(input4, "type", "text");
			attr(input4, "placeholder", "Required to perform duty tasks");
			attr(div17, "class", "control");
			attr(div19, "class", "help");
			attr(div20, "class", "field column");
			attr(label8, "class", "label");
			attr(label8, "for", "adminPW");
			attr(input5, "name", "adminPW");
			attr(input5, "class", "input");
			attr(input5, "type", "text");
			attr(input5, "placeholder", "Required to perform administration tasks e.g. modify the draw");
			attr(div21, "class", "control");
			attr(div23, "class", "help");
			attr(div24, "class", "field column");
			attr(div25, "class", "columns");
			attr(button, "class", "button is-primary is-outlined");
			attr(button, "type", "submit");
			attr(div26, "class", "control");
			attr(div27, "class", "field is-grouped");
		},
		m(target, anchor) {
			mount_component(pagetitle, target, anchor);
			insert(target, t0, anchor);
			insert(target, p0, anchor);
			insert(target, t2, anchor);
			insert(target, p1, anchor);
			append(p1, t3);
			mount_component(link, p1, null);
			insert(target, t4, anchor);
			insert(target, hr, anchor);
			insert(target, t5, anchor);
			insert(target, div7, anchor);
			append(div7, div1);
			append(div1, label0);
			append(div1, t8);
			append(div1, div0);
			append(div0, input0);
			set_input_value(input0, /*name*/ ctx[0]);
			append(div7, t9);
			append(div7, div3);
			append(div3, label1);
			append(div3, t11);
			append(div3, div2);
			append(div2, input1);
			set_input_value(input1, /*address*/ ctx[1]);
			append(div7, t12);
			append(div7, div6);
			append(div6, label2);
			append(div6, t14);
			append(div6, div4);
			append(div4, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*timezone*/ ctx[4]);
			append(div6, t15);
			append(div6, div5);
			insert(target, t17, anchor);
			insert(target, div16, anchor);
			append(div16, div11);
			append(div11, label3);
			append(div11, t20);
			append(div11, div9);
			append(div9, div8);
			append(div8, label4);
			append(label4, input2);
			append(label4, t21);
			append(label4, span4);
			append(span4, span2);
			mount_component(icon0, span2, null);
			append(span4, t22);
			append(span4, span3);
			append(label4, t24);
			if (if_block0) if_block0.m(label4, null);
			append(div11, t25);
			append(div11, div10);
			append(div16, t37);
			append(div16, div15);
			append(div15, label5);
			append(div15, t40);
			append(div15, div13);
			append(div13, div12);
			append(div12, label6);
			append(label6, input3);
			append(label6, t41);
			append(label6, span8);
			append(span8, span6);
			mount_component(icon1, span6, null);
			append(span8, t42);
			append(span8, span7);
			append(label6, t44);
			if (if_block1) if_block1.m(label6, null);
			append(div15, t45);
			append(div15, div14);
			insert(target, t57, anchor);
			insert(target, div25, anchor);
			append(div25, div20);
			append(div20, label7);
			append(div20, t60);
			append(div20, div17);
			append(div17, input4);
			set_input_value(input4, /*dutyPassword*/ ctx[3]);
			append(div20, t61);
			append(div20, div19);
			append(div25, t64);
			append(div25, div24);
			append(div24, label8);
			append(div24, t66);
			append(div24, div21);
			append(div21, input5);
			set_input_value(input5, /*adminPassword*/ ctx[2]);
			append(div24, t67);
			append(div24, div23);
			insert(target, t70, anchor);
			insert(target, div27, anchor);
			append(div27, div26);
			append(div26, button);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*input0_input_handler*/ ctx[12]),
					listen(input1, "input", /*input1_input_handler*/ ctx[13]),
					listen(select, "change", /*select_change_handler*/ ctx[14]),
					listen(input2, "change", /*input2_change_handler*/ ctx[15]),
					listen(input2, "change", /*change_handler*/ ctx[16]),
					listen(input3, "change", /*input3_change_handler*/ ctx[17]),
					listen(input3, "change", /*change_handler_1*/ ctx[18]),
					listen(input4, "input", /*input4_input_handler*/ ctx[19]),
					listen(input5, "input", /*input5_input_handler*/ ctx[20]),
					listen(button, "click", /*click_handler*/ ctx[21])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const pagetitle_changes = {};

			if (dirty & /*$$scope*/ 67108864) {
				pagetitle_changes.$$scope = { dirty, ctx };
			}

			pagetitle.$set(pagetitle_changes);
			const link_changes = {};

			if (dirty & /*$$scope*/ 67108864) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);

			if (dirty & /*name*/ 1 && input0.value !== /*name*/ ctx[0]) {
				set_input_value(input0, /*name*/ ctx[0]);
			}

			if (dirty & /*address*/ 2 && input1.value !== /*address*/ ctx[1]) {
				set_input_value(input1, /*address*/ ctx[1]);
			}

			if (dirty & /*timezones*/ 512) {
				each_value = /*timezones*/ ctx[9];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*timezone, timezones*/ 528) {
				select_option(select, /*timezone*/ ctx[4]);
			}

			if (/*teamsFiles*/ ctx[5]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(label4, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*drawFiles*/ ctx[6]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(label6, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*dutyPassword*/ 8 && input4.value !== /*dutyPassword*/ ctx[3]) {
				set_input_value(input4, /*dutyPassword*/ ctx[3]);
			}

			if (dirty & /*adminPassword*/ 4 && input5.value !== /*adminPassword*/ ctx[2]) {
				set_input_value(input5, /*adminPassword*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(pagetitle.$$.fragment, local);
			transition_in(link.$$.fragment, local);
			transition_in(icon0.$$.fragment, local);
			transition_in(icon1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pagetitle.$$.fragment, local);
			transition_out(link.$$.fragment, local);
			transition_out(icon0.$$.fragment, local);
			transition_out(icon1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(pagetitle, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(p0);
			if (detaching) detach(t2);
			if (detaching) detach(p1);
			destroy_component(link);
			if (detaching) detach(t4);
			if (detaching) detach(hr);
			if (detaching) detach(t5);
			if (detaching) detach(div7);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t17);
			if (detaching) detach(div16);
			destroy_component(icon0);
			if (if_block0) if_block0.d();
			destroy_component(icon1);
			if (if_block1) if_block1.d();
			if (detaching) detach(t57);
			if (detaching) detach(div25);
			if (detaching) detach(t70);
			if (detaching) detach(div27);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let name = "";
	let address = "";
	let adminPassword = "";
	let dutyPassword = "";
	let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const timezones = Intl.supportedValuesOf("timeZone");
	let teamsFiles;
	let drawFiles;
	let teamsCSV;
	let drawCSV;

	const clearInfo = () => {
		$$invalidate(0, name = "");
		$$invalidate(1, address = "");
		$$invalidate(2, adminPassword = "");
		$$invalidate(3, dutyPassword = "");
		$$invalidate(4, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone);
		$$invalidate(5, teamsFiles = undefined);
		$$invalidate(6, drawFiles = undefined);
		$$invalidate(7, teamsCSV = undefined);
		$$invalidate(8, drawCSV = undefined);
	};

	const publishDraw = async () => {
		const { compId } = await post("comps", {
			name,
			address,
			adminPassword,
			dutyPassword,
			timezone,
			teamsCSV,
			drawCSV
		});

		clearInfo();

		toast.set({
			type: "success",
			title: "Published",
			message: `${name} successfully published!`
		});

		navigate(`/comps/${compId}`);
	};

	const loadFileData = e => new Response(e.srcElement.files[0]).text();

	function input0_input_handler() {
		name = this.value;
		$$invalidate(0, name);
	}

	function input1_input_handler() {
		address = this.value;
		$$invalidate(1, address);
	}

	function select_change_handler() {
		timezone = select_value(this);
		$$invalidate(4, timezone);
		$$invalidate(9, timezones);
	}

	function input2_change_handler() {
		teamsFiles = this.files;
		$$invalidate(5, teamsFiles);
	}

	const change_handler = async e => {
		$$invalidate(7, teamsCSV = await loadFileData(e));
	};

	function input3_change_handler() {
		drawFiles = this.files;
		$$invalidate(6, drawFiles);
	}

	const change_handler_1 = async e => {
		$$invalidate(8, drawCSV = await loadFileData(e));
	};

	function input4_input_handler() {
		dutyPassword = this.value;
		$$invalidate(3, dutyPassword);
	}

	function input5_input_handler() {
		adminPassword = this.value;
		$$invalidate(2, adminPassword);
	}

	const click_handler = () => publishDraw();

	return [
		name,
		address,
		adminPassword,
		dutyPassword,
		timezone,
		teamsFiles,
		drawFiles,
		teamsCSV,
		drawCSV,
		timezones,
		publishDraw,
		loadFileData,
		input0_input_handler,
		input1_input_handler,
		select_change_handler,
		input2_change_handler,
		change_handler,
		input3_change_handler,
		change_handler_1,
		input4_input_handler,
		input5_input_handler,
		click_handler
	];
}

class Publish extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Publish;