/* components/StartDutyButton.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { Link } from "svelte-routing";

function create_default_slot(ctx) {
	let button;
	let t_value = /*getLabel*/ ctx[2]() + "";
	let t;
	let button_class_value;

	return {
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "class", button_class_value = /*getBtnClass*/ ctx[3]());
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);
		},
		p(ctx, dirty) {
			if (dirty & /*getLabel*/ 4 && t_value !== (t_value = /*getLabel*/ ctx[2]() + "")) set_data(t, t_value);

			if (dirty & /*getBtnClass*/ 8 && button_class_value !== (button_class_value = /*getBtnClass*/ ctx[3]())) {
				attr(button, "class", button_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
		}
	};
}

function create_fragment(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				to: `comps/${/*compId*/ ctx[1]}/games/${/*game*/ ctx[0].id}/duty`,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const link_changes = {};
			if (dirty & /*compId, game*/ 3) link_changes.to = `comps/${/*compId*/ ctx[1]}/games/${/*game*/ ctx[0].id}/duty`;

			if (dirty & /*$$scope, getBtnClass, getLabel*/ 28) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let getBtnClass;
	let getLabel;
	let { game } = $$props;
	let { compId } = $$props;

	$$self.$$set = $$props => {
		if ('game' in $$props) $$invalidate(0, game = $$props.game);
		if ('compId' in $$props) $$invalidate(1, compId = $$props.compId);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*game*/ 1) {
			$: $$invalidate(3, getBtnClass = () => {
				const classes = "button is-very-small is-outlined";

				switch (game.gameState) {
					case "pending":
						return `${classes} is-primary`;
					case "finished":
						return `${classes} is-danger`;
					default:
						return `${classes} is-warning`;
				}
			});
		}

		if ($$self.$$.dirty & /*game*/ 1) {
			$: $$invalidate(2, getLabel = () => {
				switch (game.gameState) {
					case "pending":
						return "Start Game";
					case "finished":
						return `${game.t1Goals} vs ${game.t2Goals}`;
					default:
						return "Continue";
				}
			});
		}
	};

	return [game, compId, getLabel, getBtnClass];
}

class StartDutyButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { game: 0, compId: 1 });
	}
}

export default StartDutyButton;