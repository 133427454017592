
// team is just a string name...
// pools { name, type, teams }. // rounds.
const createPool = (name, teams = [], type = "roundRobin", optionalFields = {}) => ({ rounds: 1, ...optionalFields, name, type, teams });
const createGame = (poolName, t1, t2, optionalFields = {}) => ({ ...optionalFields, pool: poolName, t1, t2 });

const rotateTeams = (row1, row2) => {
    if (row1.length < 2) return;

    var firstTeamStatic = row1.shift();

    // rotate teams.
    var row1Item = row1.shift();
    var row2Item = row2.pop();
    row2.unshift(row1Item);
    row1.push(row2Item);

    // put static item back in.
    row1.unshift(firstTeamStatic);
}

const roundRobinGames = (pool, round) => {
    let teams = [...pool.teams];
    if (teams.length % 2 !== 0) {
        teams.push("bye");
    }
    const len = teams.length,
        row1 = teams.slice(0, len / 2),
        row2 = teams.slice(len / 2, len),
        rounds = len,
        games = [];

    for (var i = 1; i < rounds; i++) {
        for (var t = 0; t < row1.length; t++) {
            if (t === 0 && i % 2 === 0) // swap home away for static team every second round.
                games.push(createGame(row1[t], row2[t], (round * (rounds - 1)) + i));
            else
                games.push(createGame(row2[t], row1[t], (round * (rounds - 1)) + i));
        }
        rotateTeams(row1, row2);
    }
    return games;
}

const isSameName = (name1, name2) => name1.trim().toLowerCase() === name2.trim().toLowerCase();


const poolToGames = (pool) => {
    const poolName = pool.name;
    let games;
    if (pool.type === "grandFinal") {
        games = [createGame(poolName, pool.teams[0], pool.teams[1])];
    } else if (pool.type === "semiFinal") {
        games = [createGame(poolName, pool.teams[0], pool.teams[3]), createGame(poolName, pool.teams[1], pool.teams[2])];
    } else {
        games = Array(pool.rounds).reduce((acc, _value, index) =>
            [].concat(acc, roundRobinGames(pool, index))
            , [])
    }
    return games;
}

export { poolToGames, createGame, createPool, isSameName };

