/* components/Wizard.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_slot,
	destroy_each,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import { createEventDispatcher } from "svelte";
const get_page1_slot_changes_1 = dirty => ({});
const get_page1_slot_context_1 = ctx => ({});
const get_page5_slot_changes = dirty => ({});
const get_page5_slot_context = ctx => ({});
const get_page4_slot_changes = dirty => ({});
const get_page4_slot_context = ctx => ({});
const get_page3_slot_changes = dirty => ({});
const get_page3_slot_context = ctx => ({});
const get_page2_slot_changes = dirty => ({});
const get_page2_slot_context = ctx => ({});
const get_page1_slot_changes = dirty => ({});
const get_page1_slot_context = ctx => ({});

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (20:8) {#each pages as page}
function create_each_block(ctx) {
	let li;
	let t_value = /*page*/ ctx[8] + "";
	let t;

	return {
		c() {
			li = element("li");
			t = text(t_value);
			attr(li, "class", "is-capitalized svelte-5s4j0x");
			toggle_class(li, "is-active", /*page*/ ctx[8] === /*currentPage*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t);
		},
		p(ctx, dirty) {
			if (dirty & /*pages*/ 1 && t_value !== (t_value = /*page*/ ctx[8] + "")) set_data(t, t_value);

			if (dirty & /*pages, currentPage*/ 3) {
				toggle_class(li, "is-active", /*page*/ ctx[8] === /*currentPage*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (30:4) {#if currentPage === "NEVER!"}
function create_if_block_8(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (33:4) {#if currentPage === pages[0]}
function create_if_block_7(ctx) {
	let current;
	const page1_slot_template = /*#slots*/ ctx[5].page1;
	const page1_slot = create_slot(page1_slot_template, ctx, /*$$scope*/ ctx[4], get_page1_slot_context);

	return {
		c() {
			if (page1_slot) page1_slot.c();
		},
		m(target, anchor) {
			if (page1_slot) {
				page1_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page1_slot) {
				if (page1_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page1_slot,
						page1_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page1_slot_template, /*$$scope*/ ctx[4], dirty, get_page1_slot_changes),
						get_page1_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page1_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page1_slot, local);
			current = false;
		},
		d(detaching) {
			if (page1_slot) page1_slot.d(detaching);
		}
	};
}

// (36:4) {#if currentPage === pages[1]}
function create_if_block_6(ctx) {
	let current;
	const page2_slot_template = /*#slots*/ ctx[5].page2;
	const page2_slot = create_slot(page2_slot_template, ctx, /*$$scope*/ ctx[4], get_page2_slot_context);

	return {
		c() {
			if (page2_slot) page2_slot.c();
		},
		m(target, anchor) {
			if (page2_slot) {
				page2_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page2_slot) {
				if (page2_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page2_slot,
						page2_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page2_slot_template, /*$$scope*/ ctx[4], dirty, get_page2_slot_changes),
						get_page2_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page2_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page2_slot, local);
			current = false;
		},
		d(detaching) {
			if (page2_slot) page2_slot.d(detaching);
		}
	};
}

// (39:4) {#if currentPage === pages[2]}
function create_if_block_5(ctx) {
	let current;
	const page3_slot_template = /*#slots*/ ctx[5].page3;
	const page3_slot = create_slot(page3_slot_template, ctx, /*$$scope*/ ctx[4], get_page3_slot_context);

	return {
		c() {
			if (page3_slot) page3_slot.c();
		},
		m(target, anchor) {
			if (page3_slot) {
				page3_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page3_slot) {
				if (page3_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page3_slot,
						page3_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page3_slot_template, /*$$scope*/ ctx[4], dirty, get_page3_slot_changes),
						get_page3_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page3_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page3_slot, local);
			current = false;
		},
		d(detaching) {
			if (page3_slot) page3_slot.d(detaching);
		}
	};
}

// (42:4) {#if currentPage === pages[3]}
function create_if_block_4(ctx) {
	let current;
	const page4_slot_template = /*#slots*/ ctx[5].page4;
	const page4_slot = create_slot(page4_slot_template, ctx, /*$$scope*/ ctx[4], get_page4_slot_context);

	return {
		c() {
			if (page4_slot) page4_slot.c();
		},
		m(target, anchor) {
			if (page4_slot) {
				page4_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page4_slot) {
				if (page4_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page4_slot,
						page4_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page4_slot_template, /*$$scope*/ ctx[4], dirty, get_page4_slot_changes),
						get_page4_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page4_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page4_slot, local);
			current = false;
		},
		d(detaching) {
			if (page4_slot) page4_slot.d(detaching);
		}
	};
}

// (45:4) {#if currentPage === pages[4]}
function create_if_block_3(ctx) {
	let current;
	const page5_slot_template = /*#slots*/ ctx[5].page5;
	const page5_slot = create_slot(page5_slot_template, ctx, /*$$scope*/ ctx[4], get_page5_slot_context);

	return {
		c() {
			if (page5_slot) page5_slot.c();
		},
		m(target, anchor) {
			if (page5_slot) {
				page5_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page5_slot) {
				if (page5_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page5_slot,
						page5_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page5_slot_template, /*$$scope*/ ctx[4], dirty, get_page5_slot_changes),
						get_page5_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page5_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page5_slot, local);
			current = false;
		},
		d(detaching) {
			if (page5_slot) page5_slot.d(detaching);
		}
	};
}

// (48:4) {#if currentPage === pages[5]}
function create_if_block_2(ctx) {
	let current;
	const page1_slot_template = /*#slots*/ ctx[5].page1;
	const page1_slot = create_slot(page1_slot_template, ctx, /*$$scope*/ ctx[4], get_page1_slot_context_1);

	return {
		c() {
			if (page1_slot) page1_slot.c();
		},
		m(target, anchor) {
			if (page1_slot) {
				page1_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (page1_slot) {
				if (page1_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						page1_slot,
						page1_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(page1_slot_template, /*$$scope*/ ctx[4], dirty, get_page1_slot_changes_1),
						get_page1_slot_context_1
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(page1_slot, local);
			current = true;
		},
		o(local) {
			transition_out(page1_slot, local);
			current = false;
		},
		d(detaching) {
			if (page1_slot) page1_slot.d(detaching);
		}
	};
}

// (53:6) {#if currentPage !== pages[0]}
function create_if_block_1(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "back";
			attr(button, "class", "button");
			attr(div, "class", "column is-narrow");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (59:6) {#if currentPage !== pages.slice(-1)}
function create_if_block(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "Next";
			attr(button, "class", "button is-primary is-outlined");
			set_style(button, "min-width", "20rem");
			attr(div, "class", "column is-narrow");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_1*/ ctx[7]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div8;
	let div4;
	let div3;
	let div2;
	let div1;
	let div0;
	let div0_style_value;
	let t0;
	let ul;
	let t1;
	let div7;
	let h5;
	let t2;
	let t3;
	let hr;
	let t4;
	let t5;
	let t6;
	let t7;
	let t8;
	let t9;
	let t10;
	let t11;
	let div6;
	let t12;
	let div5;
	let t13;
	let show_if = /*currentPage*/ ctx[1] !== /*pages*/ ctx[0].slice(-1);
	let current;
	let each_value = /*pages*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = /*currentPage*/ ctx[1] === "NEVER!" && create_if_block_8(ctx);
	let if_block1 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][0] && create_if_block_7(ctx);
	let if_block2 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][1] && create_if_block_6(ctx);
	let if_block3 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][2] && create_if_block_5(ctx);
	let if_block4 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][3] && create_if_block_4(ctx);
	let if_block5 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][4] && create_if_block_3(ctx);
	let if_block6 = /*currentPage*/ ctx[1] === /*pages*/ ctx[0][5] && create_if_block_2(ctx);
	let if_block7 = /*currentPage*/ ctx[1] !== /*pages*/ ctx[0][0] && create_if_block_1(ctx);
	let if_block8 = show_if && create_if_block(ctx);

	return {
		c() {
			div8 = element("div");
			div4 = element("div");
			div3 = element("div");
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			t0 = space();
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			div7 = element("div");
			h5 = element("h5");
			t2 = text(/*currentPage*/ ctx[1]);
			t3 = space();
			hr = element("hr");
			t4 = space();
			if (if_block0) if_block0.c();
			t5 = space();
			if (if_block1) if_block1.c();
			t6 = space();
			if (if_block2) if_block2.c();
			t7 = space();
			if (if_block3) if_block3.c();
			t8 = space();
			if (if_block4) if_block4.c();
			t9 = space();
			if (if_block5) if_block5.c();
			t10 = space();
			if (if_block6) if_block6.c();
			t11 = space();
			div6 = element("div");
			if (if_block7) if_block7.c();
			t12 = space();
			div5 = element("div");
			t13 = space();
			if (if_block8) if_block8.c();
			attr(div0, "class", "progress-bar svelte-5s4j0x");
			attr(div0, "style", div0_style_value = `height: ${/*percentProgress*/ ctx[2]()}%;`);
			attr(div1, "class", "progress progress-bar-vertical svelte-5s4j0x");
			set_style(div1, "transform", "rotate(180deg)");
			attr(div2, "class", "column pr-1");
			attr(ul, "class", "column pl-0 is-no-wrap");
			attr(div3, "class", "columns");
			attr(div4, "class", "column is-narrow ");
			attr(h5, "class", "title is-6 mb-0 is-capitalized");
			attr(hr, "class", "mb-1 mt-0");
			attr(div5, "class", "column");
			attr(div6, "class", "columns mt-3");
			attr(div7, "class", "column");
			attr(div8, "class", "columns");
		},
		m(target, anchor) {
			insert(target, div8, anchor);
			append(div8, div4);
			append(div4, div3);
			append(div3, div2);
			append(div2, div1);
			append(div1, div0);
			append(div3, t0);
			append(div3, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			append(div8, t1);
			append(div8, div7);
			append(div7, h5);
			append(h5, t2);
			append(div7, t3);
			append(div7, hr);
			append(div7, t4);
			if (if_block0) if_block0.m(div7, null);
			append(div7, t5);
			if (if_block1) if_block1.m(div7, null);
			append(div7, t6);
			if (if_block2) if_block2.m(div7, null);
			append(div7, t7);
			if (if_block3) if_block3.m(div7, null);
			append(div7, t8);
			if (if_block4) if_block4.m(div7, null);
			append(div7, t9);
			if (if_block5) if_block5.m(div7, null);
			append(div7, t10);
			if (if_block6) if_block6.m(div7, null);
			append(div7, t11);
			append(div7, div6);
			if (if_block7) if_block7.m(div6, null);
			append(div6, t12);
			append(div6, div5);
			append(div6, t13);
			if (if_block8) if_block8.m(div6, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*percentProgress*/ 4 && div0_style_value !== (div0_style_value = `height: ${/*percentProgress*/ ctx[2]()}%;`)) {
				attr(div0, "style", div0_style_value);
			}

			if (dirty & /*pages, currentPage*/ 3) {
				each_value = /*pages*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!current || dirty & /*currentPage*/ 2) set_data(t2, /*currentPage*/ ctx[1]);

			if (/*currentPage*/ ctx[1] === "NEVER!") {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*currentPage*/ 2) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_8(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div7, t5);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_7(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div7, t6);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][1]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_6(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div7, t7);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][2]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_5(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(div7, t8);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][3]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_4(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(div7, t9);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][4]) {
				if (if_block5) {
					if_block5.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block_3(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(div7, t10);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] === /*pages*/ ctx[0][5]) {
				if (if_block6) {
					if_block6.p(ctx, dirty);

					if (dirty & /*currentPage, pages*/ 3) {
						transition_in(if_block6, 1);
					}
				} else {
					if_block6 = create_if_block_2(ctx);
					if_block6.c();
					transition_in(if_block6, 1);
					if_block6.m(div7, t11);
				}
			} else if (if_block6) {
				group_outros();

				transition_out(if_block6, 1, 1, () => {
					if_block6 = null;
				});

				check_outros();
			}

			if (/*currentPage*/ ctx[1] !== /*pages*/ ctx[0][0]) {
				if (if_block7) {
					if_block7.p(ctx, dirty);
				} else {
					if_block7 = create_if_block_1(ctx);
					if_block7.c();
					if_block7.m(div6, t12);
				}
			} else if (if_block7) {
				if_block7.d(1);
				if_block7 = null;
			}

			if (dirty & /*currentPage, pages*/ 3) show_if = /*currentPage*/ ctx[1] !== /*pages*/ ctx[0].slice(-1);

			if (show_if) {
				if (if_block8) {
					if_block8.p(ctx, dirty);
				} else {
					if_block8 = create_if_block(ctx);
					if_block8.c();
					if_block8.m(div6, null);
				}
			} else if (if_block8) {
				if_block8.d(1);
				if_block8 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			transition_in(if_block4);
			transition_in(if_block5);
			transition_in(if_block6);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			transition_out(if_block4);
			transition_out(if_block5);
			transition_out(if_block6);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div8);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (if_block7) if_block7.d();
			if (if_block8) if_block8.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let percentProgress;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { pages } = $$props;
	let { currentPage = pages[0] } = $$props;
	const dispatch = createEventDispatcher();
	const click_handler = () => dispatch("back");
	const click_handler_1 = () => dispatch("next");

	$$self.$$set = $$props => {
		if ('pages' in $$props) $$invalidate(0, pages = $$props.pages);
		if ('currentPage' in $$props) $$invalidate(1, currentPage = $$props.currentPage);
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*pages, currentPage*/ 3) {
			$: $$invalidate(2, percentProgress = () => (pages.indexOf(currentPage) + 1) / pages.length * 100);
		}
	};

	return [
		pages,
		currentPage,
		percentProgress,
		dispatch,
		$$scope,
		slots,
		click_handler,
		click_handler_1
	];
}

class Wizard extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { pages: 0, currentPage: 1 });
	}
}

export default Wizard;