/* components/Tabs.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	create_in_transition,
	create_slot,
	destroy_each,
	detach,
	element,
	empty,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert,
	listen,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import { fade } from "svelte/transition";
import { createEventDispatcher } from "svelte";
const get_tab1_slot_changes_1 = dirty => ({});
const get_tab1_slot_context_1 = ctx => ({});
const get_tab5_slot_changes = dirty => ({});
const get_tab5_slot_context = ctx => ({});
const get_tab4_slot_changes = dirty => ({});
const get_tab4_slot_context = ctx => ({});
const get_tab3_slot_changes = dirty => ({});
const get_tab3_slot_context = ctx => ({});
const get_tab2_slot_changes = dirty => ({});
const get_tab2_slot_context = ctx => ({});
const get_tab1_slot_changes = dirty => ({});
const get_tab1_slot_context = ctx => ({});

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

// (13:4) {#each tabs as tab}
function create_each_block(ctx) {
	let li;
	let a;
	let t0_value = /*tab*/ ctx[6] + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[5](/*tab*/ ctx[6]);
	}

	return {
		c() {
			li = element("li");
			a = element("a");
			t0 = text(t0_value);
			t1 = space();
			attr(li, "class", "is-capitalized");
			toggle_class(li, "is-active", /*tab*/ ctx[6] === /*currentTab*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, a);
			append(a, t0);
			append(li, t1);

			if (!mounted) {
				dispose = listen(a, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*tabs*/ 2 && t0_value !== (t0_value = /*tab*/ ctx[6] + "")) set_data(t0, t0_value);

			if (dirty & /*tabs, currentTab*/ 3) {
				toggle_class(li, "is-active", /*tab*/ ctx[6] === /*currentTab*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(li);
			mounted = false;
			dispose();
		}
	};
}

// (27:0) {#if currentTab === 'NEVER!'}
function create_if_block_6(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (30:0) {#if currentTab === tabs[0]}
function create_if_block_5(ctx) {
	let div;
	let div_intro;
	let current;
	const tab1_slot_template = /*#slots*/ ctx[4].tab1;
	const tab1_slot = create_slot(tab1_slot_template, ctx, /*$$scope*/ ctx[3], get_tab1_slot_context);

	return {
		c() {
			div = element("div");
			if (tab1_slot) tab1_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab1_slot) {
				tab1_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab1_slot) {
				if (tab1_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab1_slot,
						tab1_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab1_slot_template, /*$$scope*/ ctx[3], dirty, get_tab1_slot_changes),
						get_tab1_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab1_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab1_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab1_slot) tab1_slot.d(detaching);
		}
	};
}

// (35:0) {#if currentTab === tabs[1]}
function create_if_block_4(ctx) {
	let div;
	let div_intro;
	let current;
	const tab2_slot_template = /*#slots*/ ctx[4].tab2;
	const tab2_slot = create_slot(tab2_slot_template, ctx, /*$$scope*/ ctx[3], get_tab2_slot_context);

	return {
		c() {
			div = element("div");
			if (tab2_slot) tab2_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab2_slot) {
				tab2_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab2_slot) {
				if (tab2_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab2_slot,
						tab2_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab2_slot_template, /*$$scope*/ ctx[3], dirty, get_tab2_slot_changes),
						get_tab2_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab2_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab2_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab2_slot) tab2_slot.d(detaching);
		}
	};
}

// (40:0) {#if currentTab === tabs[2]}
function create_if_block_3(ctx) {
	let div;
	let div_intro;
	let current;
	const tab3_slot_template = /*#slots*/ ctx[4].tab3;
	const tab3_slot = create_slot(tab3_slot_template, ctx, /*$$scope*/ ctx[3], get_tab3_slot_context);

	return {
		c() {
			div = element("div");
			if (tab3_slot) tab3_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab3_slot) {
				tab3_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab3_slot) {
				if (tab3_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab3_slot,
						tab3_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab3_slot_template, /*$$scope*/ ctx[3], dirty, get_tab3_slot_changes),
						get_tab3_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab3_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab3_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab3_slot) tab3_slot.d(detaching);
		}
	};
}

// (45:0) {#if currentTab === tabs[3]}
function create_if_block_2(ctx) {
	let div;
	let div_intro;
	let current;
	const tab4_slot_template = /*#slots*/ ctx[4].tab4;
	const tab4_slot = create_slot(tab4_slot_template, ctx, /*$$scope*/ ctx[3], get_tab4_slot_context);

	return {
		c() {
			div = element("div");
			if (tab4_slot) tab4_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab4_slot) {
				tab4_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab4_slot) {
				if (tab4_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab4_slot,
						tab4_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab4_slot_template, /*$$scope*/ ctx[3], dirty, get_tab4_slot_changes),
						get_tab4_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab4_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab4_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab4_slot) tab4_slot.d(detaching);
		}
	};
}

// (50:0) {#if currentTab === tabs[4]}
function create_if_block_1(ctx) {
	let div;
	let div_intro;
	let current;
	const tab5_slot_template = /*#slots*/ ctx[4].tab5;
	const tab5_slot = create_slot(tab5_slot_template, ctx, /*$$scope*/ ctx[3], get_tab5_slot_context);

	return {
		c() {
			div = element("div");
			if (tab5_slot) tab5_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab5_slot) {
				tab5_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab5_slot) {
				if (tab5_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab5_slot,
						tab5_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab5_slot_template, /*$$scope*/ ctx[3], dirty, get_tab5_slot_changes),
						get_tab5_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab5_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab5_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab5_slot) tab5_slot.d(detaching);
		}
	};
}

// (55:0) {#if currentTab === tabs[5]}
function create_if_block(ctx) {
	let div;
	let div_intro;
	let current;
	const tab1_slot_template = /*#slots*/ ctx[4].tab1;
	const tab1_slot = create_slot(tab1_slot_template, ctx, /*$$scope*/ ctx[3], get_tab1_slot_context_1);

	return {
		c() {
			div = element("div");
			if (tab1_slot) tab1_slot.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (tab1_slot) {
				tab1_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (tab1_slot) {
				if (tab1_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						tab1_slot,
						tab1_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(tab1_slot_template, /*$$scope*/ ctx[3], dirty, get_tab1_slot_changes_1),
						get_tab1_slot_context_1
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(tab1_slot, local);

			if (!div_intro) {
				add_render_callback(() => {
					div_intro = create_in_transition(div, fade, { delay: 250, duration: 250 });
					div_intro.start();
				});
			}

			current = true;
		},
		o(local) {
			transition_out(tab1_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (tab1_slot) tab1_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let ul;
	let t0;
	let t1;
	let t2;
	let t3;
	let t4;
	let t5;
	let t6;
	let if_block6_anchor;
	let current;
	let each_value = /*tabs*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = /*currentTab*/ ctx[0] === 'NEVER!' && create_if_block_6(ctx);
	let if_block1 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][0] && create_if_block_5(ctx);
	let if_block2 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][1] && create_if_block_4(ctx);
	let if_block3 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][2] && create_if_block_3(ctx);
	let if_block4 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][3] && create_if_block_2(ctx);
	let if_block5 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][4] && create_if_block_1(ctx);
	let if_block6 = /*currentTab*/ ctx[0] === /*tabs*/ ctx[1][5] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			t3 = space();
			if (if_block3) if_block3.c();
			t4 = space();
			if (if_block4) if_block4.c();
			t5 = space();
			if (if_block5) if_block5.c();
			t6 = space();
			if (if_block6) if_block6.c();
			if_block6_anchor = empty();
			attr(div, "class", "tabs is-boxed is-hidden-print");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			insert(target, t0, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t2, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, t3, anchor);
			if (if_block3) if_block3.m(target, anchor);
			insert(target, t4, anchor);
			if (if_block4) if_block4.m(target, anchor);
			insert(target, t5, anchor);
			if (if_block5) if_block5.m(target, anchor);
			insert(target, t6, anchor);
			if (if_block6) if_block6.m(target, anchor);
			insert(target, if_block6_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*tabs, currentTab, dispatch*/ 7) {
				each_value = /*tabs*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*currentTab*/ ctx[0] === 'NEVER!') {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*currentTab*/ 1) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_6(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t1.parentNode, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_5(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t2.parentNode, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][1]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_4(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(t3.parentNode, t3);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][2]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_3(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(t4.parentNode, t4);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][3]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_2(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(t5.parentNode, t5);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][4]) {
				if (if_block5) {
					if_block5.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block_1(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(t6.parentNode, t6);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}

			if (/*currentTab*/ ctx[0] === /*tabs*/ ctx[1][5]) {
				if (if_block6) {
					if_block6.p(ctx, dirty);

					if (dirty & /*currentTab, tabs*/ 3) {
						transition_in(if_block6, 1);
					}
				} else {
					if_block6 = create_if_block(ctx);
					if_block6.c();
					transition_in(if_block6, 1);
					if_block6.m(if_block6_anchor.parentNode, if_block6_anchor);
				}
			} else if (if_block6) {
				group_outros();

				transition_out(if_block6, 1, 1, () => {
					if_block6 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			transition_in(if_block4);
			transition_in(if_block5);
			transition_in(if_block6);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			transition_out(if_block4);
			transition_out(if_block5);
			transition_out(if_block6);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t0);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t2);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(t3);
			if (if_block3) if_block3.d(detaching);
			if (detaching) detach(t4);
			if (if_block4) if_block4.d(detaching);
			if (detaching) detach(t5);
			if (if_block5) if_block5.d(detaching);
			if (detaching) detach(t6);
			if (if_block6) if_block6.d(detaching);
			if (detaching) detach(if_block6_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { tabs } = $$props;
	let { currentTab = tabs[0] } = $$props;
	const dispatch = createEventDispatcher();

	const click_handler = tab => {
		$$invalidate(0, currentTab = tab);
		dispatch('tabChange', tab);
	};

	$$self.$$set = $$props => {
		if ('tabs' in $$props) $$invalidate(1, tabs = $$props.tabs);
		if ('currentTab' in $$props) $$invalidate(0, currentTab = $$props.currentTab);
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [currentTab, tabs, dispatch, $$scope, slots, click_handler];
}

class Tabs extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { tabs: 1, currentTab: 0 });
	}
}

export default Tabs;