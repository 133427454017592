/* components/EditTeams.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import EditTeamModal from "../components/EditTeamModal.svelte";
import PasswordModal from "../components/PasswordModal.svelte";
import { onMount } from "svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

// (27:2) {#each teams as team}
function create_each_block(ctx) {
	let li;
	let button;
	let t0_value = /*team*/ ctx[10].name + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[8](/*team*/ ctx[10]);
	}

	return {
		c() {
			li = element("li");
			button = element("button");
			t0 = text(t0_value);
			t1 = space();
			attr(button, "class", "button is-dark is-outlined is-capitalized");
			attr(li, "class", "my-1");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, button);
			append(button, t0);
			append(li, t1);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(li);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let passwordmodal;
	let updating_open;
	let t0;
	let lu;
	let t1;
	let editteammodal;
	let updating_open_1;
	let current;

	function passwordmodal_open_binding(value) {
		/*passwordmodal_open_binding*/ ctx[7](value);
	}

	let passwordmodal_props = { compId: /*compId*/ ctx[0] };

	if (/*showPasswordModal*/ ctx[2] !== void 0) {
		passwordmodal_props.open = /*showPasswordModal*/ ctx[2];
	}

	passwordmodal = new PasswordModal({ props: passwordmodal_props });
	binding_callbacks.push(() => bind(passwordmodal, 'open', passwordmodal_open_binding));
	let each_value = /*teams*/ ctx[4];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	function editteammodal_open_binding(value) {
		/*editteammodal_open_binding*/ ctx[9](value);
	}

	let editteammodal_props = {
		teamUuid: /*teamToEdit*/ ctx[3].uuid,
		compId: /*compId*/ ctx[0]
	};

	if (/*showEditModal*/ ctx[1] !== void 0) {
		editteammodal_props.open = /*showEditModal*/ ctx[1];
	}

	editteammodal = new EditTeamModal({ props: editteammodal_props });
	binding_callbacks.push(() => bind(editteammodal, 'open', editteammodal_open_binding));

	return {
		c() {
			create_component(passwordmodal.$$.fragment);
			t0 = space();
			lu = element("lu");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			create_component(editteammodal.$$.fragment);
			set_style(lu, "list-style-type", "none");
		},
		m(target, anchor) {
			mount_component(passwordmodal, target, anchor);
			insert(target, t0, anchor);
			insert(target, lu, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(lu, null);
			}

			insert(target, t1, anchor);
			mount_component(editteammodal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const passwordmodal_changes = {};
			if (dirty & /*compId*/ 1) passwordmodal_changes.compId = /*compId*/ ctx[0];

			if (!updating_open && dirty & /*showPasswordModal*/ 4) {
				updating_open = true;
				passwordmodal_changes.open = /*showPasswordModal*/ ctx[2];
				add_flush_callback(() => updating_open = false);
			}

			passwordmodal.$set(passwordmodal_changes);

			if (dirty & /*openEditTeamModal, teams*/ 48) {
				each_value = /*teams*/ ctx[4];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(lu, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			const editteammodal_changes = {};
			if (dirty & /*teamToEdit*/ 8) editteammodal_changes.teamUuid = /*teamToEdit*/ ctx[3].uuid;
			if (dirty & /*compId*/ 1) editteammodal_changes.compId = /*compId*/ ctx[0];

			if (!updating_open_1 && dirty & /*showEditModal*/ 2) {
				updating_open_1 = true;
				editteammodal_changes.open = /*showEditModal*/ ctx[1];
				add_flush_callback(() => updating_open_1 = false);
			}

			editteammodal.$set(editteammodal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(passwordmodal.$$.fragment, local);
			transition_in(editteammodal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(passwordmodal.$$.fragment, local);
			transition_out(editteammodal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(passwordmodal, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(lu);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t1);
			destroy_component(editteammodal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let teamToEdit;
	let { compId } = $$props;
	let { teamsLookup } = $$props;
	let teams = Object.values(teamsLookup).sort((a, b) => a.name < b.name);
	let showEditModal = false;
	let showPasswordModal = false;

	const openEditTeamModal = team => {
		$$invalidate(3, teamToEdit = team);
		$$invalidate(1, showEditModal = true);
	};

	onMount(async () => {
		$$invalidate(2, showPasswordModal = true);
	});

	function passwordmodal_open_binding(value) {
		showPasswordModal = value;
		$$invalidate(2, showPasswordModal);
	}

	const click_handler = team => openEditTeamModal(team);

	function editteammodal_open_binding(value) {
		showEditModal = value;
		$$invalidate(1, showEditModal);
	}

	$$self.$$set = $$props => {
		if ('compId' in $$props) $$invalidate(0, compId = $$props.compId);
		if ('teamsLookup' in $$props) $$invalidate(6, teamsLookup = $$props.teamsLookup);
	};

	$: $$invalidate(3, teamToEdit = {});

	return [
		compId,
		showEditModal,
		showPasswordModal,
		teamToEdit,
		teams,
		openEditTeamModal,
		teamsLookup,
		passwordmodal_open_binding,
		click_handler,
		editteammodal_open_binding
	];
}

class EditTeams extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { compId: 0, teamsLookup: 6 });
	}
}

export default EditTeams;