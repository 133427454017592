/* components/Admin.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	add_flush_callback,
	attr,
	bind,
	binding_callbacks,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { del, post } from "../utils/api";
import PasswordModal from "../components/PasswordModal.svelte";
import { onMount } from "svelte";
import { loggedIn } from "../store/store";
import { navigate } from "svelte-routing";

function create_else_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.textContent = "Please login as an admin first.";
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (28:0) {#if $loggedIn[`${comp.id}:admin`]}
function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type_1(ctx, dirty) {
		if (/*comp*/ ctx[0].disabled) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (33:2) {:else}
function create_else_block(ctx) {
	let button;
	let t1;
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Delete Comp!";
			t1 = space();
			div = element("div");
			div.textContent = "WARNING: This will disable the comp and you will not be able to see or access it from the\n      comps list. If you save this url you will be able to access it directly and enable it again.";
			attr(button, "class", "button is-danger is-outlined");
			attr(button, "aria-label", "delete");
			attr(div, "class", "help");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*deleteComp*/ ctx[3])) /*deleteComp*/ ctx[3].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (29:2) {#if comp.disabled}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Enable Comp";
			attr(button, "class", "button is-primary is-outlined");
			attr(button, "aria-label", "delete");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*enableComp*/ ctx[2])) /*enableComp*/ ctx[2].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let passwordmodal;
	let updating_open;
	let t;
	let if_block_anchor;
	let current;

	function passwordmodal_open_binding(value) {
		/*passwordmodal_open_binding*/ ctx[5](value);
	}

	let passwordmodal_props = {
		type: "admin",
		compId: /*comp*/ ctx[0].id
	};

	if (/*showPasswordModal*/ ctx[1] !== void 0) {
		passwordmodal_props.open = /*showPasswordModal*/ ctx[1];
	}

	passwordmodal = new PasswordModal({ props: passwordmodal_props });
	binding_callbacks.push(() => bind(passwordmodal, 'open', passwordmodal_open_binding));

	function select_block_type(ctx, dirty) {
		if (/*$loggedIn*/ ctx[4][`${/*comp*/ ctx[0].id}:admin`]) return create_if_block;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			create_component(passwordmodal.$$.fragment);
			t = space();
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(passwordmodal, target, anchor);
			insert(target, t, anchor);
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const passwordmodal_changes = {};
			if (dirty & /*comp*/ 1) passwordmodal_changes.compId = /*comp*/ ctx[0].id;

			if (!updating_open && dirty & /*showPasswordModal*/ 2) {
				updating_open = true;
				passwordmodal_changes.open = /*showPasswordModal*/ ctx[1];
				add_flush_callback(() => updating_open = false);
			}

			passwordmodal.$set(passwordmodal_changes);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(passwordmodal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(passwordmodal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(passwordmodal, detaching);
			if (detaching) detach(t);
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let deleteComp;
	let enableComp;
	let $loggedIn;
	component_subscribe($$self, loggedIn, $$value => $$invalidate(4, $loggedIn = $$value));
	let { comp = {} } = $$props;
	let showPasswordModal = false;

	onMount(async () => {
		$$invalidate(1, showPasswordModal = true);
	});

	function passwordmodal_open_binding(value) {
		showPasswordModal = value;
		$$invalidate(1, showPasswordModal);
	}

	$$self.$$set = $$props => {
		if ('comp' in $$props) $$invalidate(0, comp = $$props.comp);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*comp*/ 1) {
			$: $$invalidate(3, deleteComp = async () => {
				await del(`comps/${comp.id}`);
				navigate("comps");
			});
		}

		if ($$self.$$.dirty & /*comp*/ 1) {
			$: $$invalidate(2, enableComp = async () => {
				await post(`comps/${comp.id}/enable`);
				navigate("comps");
			});
		}
	};

	return [
		comp,
		showPasswordModal,
		enableComp,
		deleteComp,
		$loggedIn,
		passwordmodal_open_binding
	];
}

class Admin extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { comp: 0 });
	}
}

export default Admin;