/* components/EditTeamModal.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	handle_promise,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	to_number,
	toggle_class,
	transition_in,
	transition_out,
	update_await_block_branch
} from "svelte/internal";

import { get, post } from "../utils/api";
import { createEventDispatcher } from "svelte";
import Loading from "../components/Loading.svelte";
import Error from "../components/Error.svelte";
import Info from "../components/Info.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	child_ctx[23] = list;
	child_ctx[24] = i;
	return child_ctx;
}

// (95:0) {#if teamUuid}
function create_if_block(ctx) {
	let div2;
	let div0;
	let t0;
	let div1;
	let header;
	let p;
	let span0;
	let t2;
	let span1;
	let t3;
	let t4;
	let button0;
	let t5;
	let section;
	let promise;
	let t6;
	let t7;
	let footer;
	let button1;
	let t9;
	let button2;
	let current;
	let mounted;
	let dispose;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 3,
		error: 6,
		blocks: [,,,]
	};

	handle_promise(promise = /*getTeam*/ ctx[7](/*teamUuid*/ ctx[1]), info);
	let if_block = /*error*/ ctx[6] && create_if_block_1(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = space();
			div1 = element("div");
			header = element("header");
			p = element("p");
			span0 = element("span");
			span0.textContent = "Edit Team:";
			t2 = space();
			span1 = element("span");
			t3 = text(/*teamName*/ ctx[4]);
			t4 = space();
			button0 = element("button");
			t5 = space();
			section = element("section");
			info.block.c();
			t6 = space();
			if (if_block) if_block.c();
			t7 = space();
			footer = element("footer");
			button1 = element("button");
			button1.textContent = "Cancel";
			t9 = space();
			button2 = element("button");
			button2.textContent = "Update Team";
			attr(div0, "class", "modal-background");
			attr(span0, "class", "has-text-grey");
			attr(span1, "class", "is-capitalized is-bolder");
			attr(p, "class", "modal-card-title");
			attr(button0, "class", "delete");
			attr(button0, "aria-label", "close");
			attr(header, "class", "modal-card-head");
			attr(section, "class", "modal-card-body");
			attr(button1, "class", "button");
			attr(button2, "class", "button is-primary is-outlined");
			attr(footer, "class", "modal-card-foot");
			attr(div1, "class", "modal-card");
			attr(div2, "class", "modal");
			toggle_class(div2, "is-active", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t0);
			append(div2, div1);
			append(div1, header);
			append(header, p);
			append(p, span0);
			append(p, t2);
			append(p, span1);
			append(span1, t3);
			append(header, t4);
			append(header, button0);
			append(div1, t5);
			append(div1, section);
			info.block.m(section, info.anchor = null);
			info.mount = () => section;
			info.anchor = t6;
			append(section, t6);
			if (if_block) if_block.m(section, null);
			append(div1, t7);
			append(div1, footer);
			append(footer, button1);
			append(footer, t9);
			append(footer, button2);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[11]),
					listen(button1, "click", /*click_handler_2*/ ctx[16]),
					listen(button2, "click", /*updateTeam*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (!current || dirty & /*teamName*/ 16) set_data(t3, /*teamName*/ ctx[4]);
			info.ctx = ctx;

			if (dirty & /*teamUuid*/ 2 && promise !== (promise = /*getTeam*/ ctx[7](/*teamUuid*/ ctx[1])) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}

			if (/*error*/ ctx[6]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*error*/ 64) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(section, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (!current || dirty & /*open*/ 1) {
				toggle_class(div2, "is-active", /*open*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			info.block.d();
			info.token = null;
			info = null;
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (177:8) {:catch error}
function create_catch_block(ctx) {
	let error_1;
	let current;

	error_1 = new Error({
			props: {
				message: "An error occured loading the team..."
			}
		});

	return {
		c() {
			create_component(error_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(error_1, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(error_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(error_1, detaching);
		}
	};
}

// (109:8) {:then team}
function create_then_block(ctx) {
	let div8;
	let div2;
	let div1;
	let label0;
	let t1;
	let div0;
	let input;
	let t2;
	let div4;
	let t7;
	let t8;
	let hr;
	let t9;
	let div7;
	let div5;
	let info0;
	let t10;
	let div6;
	let info1;
	let current;
	let mounted;
	let dispose;
	let each_value = /*team*/ ctx[3].players;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	info0 = new Info({
			props: {
				message: "Player numbers must be unique."
			}
		});

	info1 = new Info({
			props: {
				message: "Removeing players will not change game scores but it will cause goals to\n                  be unassigned from that player even if you add them back."
			}
		});

	return {
		c() {
			div8 = element("div");
			div2 = element("div");
			div1 = element("div");
			label0 = element("label");
			label0.textContent = "Team Name";
			t1 = space();
			div0 = element("div");
			input = element("input");
			t2 = space();
			div4 = element("div");

			div4.innerHTML = `<label class="label column mb-0 svelte-13z1hw5" style="padding-bottom: 0px;">Number:</label> 
              
              <label class="label column mb-0 svelte-13z1hw5" style="padding-bottom: 0px;">Name:</label> 
              <div class="column is-one-fifth svelte-13z1hw5"></div>`;

			t7 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t8 = space();
			hr = element("hr");
			t9 = space();
			div7 = element("div");
			div5 = element("div");
			create_component(info0.$$.fragment);
			t10 = space();
			div6 = element("div");
			create_component(info1.$$.fragment);
			attr(label0, "class", "label");
			attr(label0, "for", "teamName");
			attr(input, "class", "input");
			attr(input, "name", "teamName");
			attr(input, "type", "text");
			attr(div0, "class", "control");
			attr(div1, "class", "field column svelte-13z1hw5");
			attr(div2, "class", "columns");
			attr(div4, "class", "columns");
			attr(hr, "class", "mt-0");
			attr(div5, "class", "column is-full svelte-13z1hw5");
			attr(div6, "class", "column is-full svelte-13z1hw5");
			attr(div7, "class", "columns is-multiline");
		},
		m(target, anchor) {
			insert(target, div8, anchor);
			append(div8, div2);
			append(div2, div1);
			append(div1, label0);
			append(div1, t1);
			append(div1, div0);
			append(div0, input);
			set_input_value(input, /*teamName*/ ctx[4]);
			append(div8, t2);
			append(div8, div4);
			append(div8, t7);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div8, null);
			}

			append(div8, t8);
			append(div8, hr);
			append(div8, t9);
			append(div8, div7);
			append(div7, div5);
			mount_component(info0, div5, null);
			append(div7, t10);
			append(div7, div6);
			mount_component(info1, div6, null);
			current = true;

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[12]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*teamName*/ 16 && input.value !== /*teamName*/ ctx[4]) {
				set_input_value(input, /*teamName*/ ctx[4]);
			}

			if (dirty & /*removePlayer, players, onInputUpdate, teamUuid*/ 550) {
				each_value = /*team*/ ctx[3].players;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div8, t8);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i(local) {
			if (current) return;
			transition_in(info0.$$.fragment, local);
			transition_in(info1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(info0.$$.fragment, local);
			transition_out(info1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div8);
			destroy_each(each_blocks, detaching);
			destroy_component(info0);
			destroy_component(info1);
			mounted = false;
			dispose();
		}
	};
}

// (151:18) {#if index + 1 < players.length}
function create_if_block_2(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	function click_handler_1() {
		return /*click_handler_1*/ ctx[15](/*index*/ ctx[24]);
	}

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "Remove";
			attr(button, "class", "button is-fullwidth is-danger is-outlined");
			attr(div, "class", "control");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (126:12) {#each team.players as player, index}
function create_each_block(ctx) {
	let div5;
	let div1;
	let div0;
	let input0;
	let t0;
	let div3;
	let div2;
	let input1;
	let t1;
	let div4;
	let mounted;
	let dispose;

	function input0_input_handler() {
		/*input0_input_handler*/ ctx[13].call(input0, /*index*/ ctx[24]);
	}

	function input1_input_handler() {
		/*input1_input_handler*/ ctx[14].call(input1, /*index*/ ctx[24]);
	}

	let if_block = /*index*/ ctx[24] + 1 < /*players*/ ctx[2].length && create_if_block_2(ctx);

	return {
		c() {
			div5 = element("div");
			div1 = element("div");
			div0 = element("div");
			input0 = element("input");
			t0 = space();
			div3 = element("div");
			div2 = element("div");
			input1 = element("input");
			t1 = space();
			div4 = element("div");
			if (if_block) if_block.c();
			attr(input0, "class", "input");
			attr(input0, "type", "number");
			attr(input0, "placeholder", "Player number");
			attr(div0, "class", "control");
			attr(div1, "class", "field column svelte-13z1hw5");
			attr(input1, "class", "input");
			attr(input1, "type", "text");
			attr(input1, "placeholder", "Player name");
			attr(div2, "class", "control");
			attr(div3, "class", "field column svelte-13z1hw5");
			attr(div4, "class", "field column is-one-fifth svelte-13z1hw5");
			attr(div5, "class", "columns");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div1);
			append(div1, div0);
			append(div0, input0);
			set_input_value(input0, /*players*/ ctx[2][/*index*/ ctx[24]].number);
			append(div5, t0);
			append(div5, div3);
			append(div3, div2);
			append(div2, input1);
			set_input_value(input1, /*players*/ ctx[2][/*index*/ ctx[24]].name);
			append(div5, t1);
			append(div5, div4);
			if (if_block) if_block.m(div4, null);

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*onInputUpdate*/ ctx[9]),
					listen(input0, "input", input0_input_handler),
					listen(input1, "input", /*onInputUpdate*/ ctx[9]),
					listen(input1, "input", input1_input_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*players*/ 4 && to_number(input0.value) !== /*players*/ ctx[2][/*index*/ ctx[24]].number) {
				set_input_value(input0, /*players*/ ctx[2][/*index*/ ctx[24]].number);
			}

			if (dirty & /*players*/ 4 && input1.value !== /*players*/ ctx[2][/*index*/ ctx[24]].name) {
				set_input_value(input1, /*players*/ ctx[2][/*index*/ ctx[24]].name);
			}

			if (/*index*/ ctx[24] + 1 < /*players*/ ctx[2].length) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div4, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div5);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (107:34)            <Loading />         {:then team}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

// (180:8) {#if error}
function create_if_block_1(ctx) {
	let error_1;
	let current;
	error_1 = new Error({ props: { message: /*error*/ ctx[6] } });

	return {
		c() {
			create_component(error_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(error_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const error_1_changes = {};
			if (dirty & /*error*/ 64) error_1_changes.message = /*error*/ ctx[6];
			error_1.$set(error_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(error_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(error_1, detaching);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*teamUuid*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*teamUuid*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*teamUuid*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let team;
	let removePlayer;
	let { open = false } = $$props;
	let { compId } = $$props;
	let { teamUuid } = $$props;
	const dispatch = createEventDispatcher();
	let players;
	let teamName = "...";
	let error;

	const pushEmptyPlayer = () => {
		team.players.push({ name: "", number: "" });
		players.push({ name: "", number: "" });
	};

	const getValidPlayers = () => players.filter(p => (p.name || "").trim() !== "" && Number.isInteger(parseInt(p.number, 10)));

	const validate = () => {
		$$invalidate(6, error = undefined);

		if (teamName === "..." || teamName.trim() === "") {
			$$invalidate(6, error = "You must define a team name.");
		}

		const validPlayers = getValidPlayers();

		const playerNumbersSet = validPlayers.reduce(
			(set, current) => {
				set.add(parseInt(current.number, 10));
				return set;
			},
			new Set()
		);

		if (playerNumbersSet.size !== validPlayers.length) {
			console.log(playerNumbersSet, validPlayers);
			$$invalidate(6, error = "Player numbers must be unique.");
		}
	};

	const getTeam = uuid => get(`comps/${compId}/teams/${uuid}`).then(t => {
		$$invalidate(3, team = t);
		$$invalidate(4, teamName = team.name);
		$$invalidate(2, players = [...t.players]);
		pushEmptyPlayer();
		return team;
	});

	const updateTeam = async () => {
		validate();

		if (!error) {
			try {
				await post(`comps/${compId}/teams/${teamUuid}`, {
					team: {
						name: teamName,
						players: [...getValidPlayers()]
					}
				});

				$$invalidate(0, open = false);
				dispatch("updated");
			} catch(e) {
				$$invalidate(6, error = e.message);
			}
		}
	};

	const isEmpty = input => {
		console.log("input", input);
		const empty = `${input || ""}`.trim() === "";
		return empty;
	};

	const onInputUpdate = () => {
		const lastPlayer = players[players.length - 1];

		if (!isEmpty(lastPlayer.number) || !isEmpty(lastPlayer.name)) {
			console.log("push new player");
			pushEmptyPlayer();
		}
	};

	const click_handler = () => $$invalidate(0, open = false);

	function input_input_handler() {
		teamName = this.value;
		$$invalidate(4, teamName);
	}

	function input0_input_handler(index) {
		players[index].number = to_number(this.value);
		($$invalidate(2, players), $$invalidate(3, team));
	}

	function input1_input_handler(index) {
		players[index].name = this.value;
		($$invalidate(2, players), $$invalidate(3, team));
	}

	const click_handler_1 = index => removePlayer(index);
	const click_handler_2 = () => $$invalidate(0, open = false);

	$$self.$$set = $$props => {
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
		if ('compId' in $$props) $$invalidate(10, compId = $$props.compId);
		if ('teamUuid' in $$props) $$invalidate(1, teamUuid = $$props.teamUuid);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*teamUuid*/ 2) {
			$: {
				if (teamUuid) {
					getTeam(teamUuid);
				}
			}
		}

		if ($$self.$$.dirty & /*team, players*/ 12) {
			$: $$invalidate(5, removePlayer = index => {
				team.players.splice(index, 1);
				players.splice(index, 1);
				$$invalidate(2, players = [...players]);
				$$invalidate(3, team.players = [...team.players], team);
			});
		}
	};

	$: $$invalidate(3, team = {});

	return [
		open,
		teamUuid,
		players,
		team,
		teamName,
		removePlayer,
		error,
		getTeam,
		updateTeam,
		onInputUpdate,
		compId,
		click_handler,
		input_input_handler,
		input0_input_handler,
		input1_input_handler,
		click_handler_1,
		click_handler_2
	];
}

class EditTeamModal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { open: 0, compId: 10, teamUuid: 1 });
	}
}

export default EditTeamModal;