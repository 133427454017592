/* components/CardBadge.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import {
	cardTypeAbbrev,
	severityToColor,
	yellowCardTimeRemaining
} from "../utils/dutyUtils";

function create_fragment(ctx) {
	let span1;
	let span0;

	let t0_value = (!/*card*/ ctx[1].player
	? "Team"
	: `#${(/*game*/ ctx[0].teamsLookup[/*game*/ ctx[0][/*slot*/ ctx[2]]].players.find(/*func*/ ctx[3]) || {}).number}`) + "";

	let t0;
	let t1;
	let t2_value = cardTypeAbbrev[/*card*/ ctx[1].type] + "";
	let t2;

	let t3_value = (/*card*/ ctx[1].player
	? `: ${yellowCardTimeRemaining(/*game*/ ctx[0], /*card*/ ctx[1])}`
	: "") + "";

	let t3;
	let span1_title_value;
	let span1_class_value;

	return {
		c() {
			span1 = element("span");
			span0 = element("span");
			t0 = text(t0_value);
			t1 = space();
			t2 = text(t2_value);
			t3 = text(t3_value);
			attr(span0, "class", "title is-6 mr-1");
			attr(span1, "title", span1_title_value = `${/*card*/ ctx[1].severity}: ${/*card*/ ctx[1].type}`);
			attr(span1, "class", span1_class_value = `tag is-${severityToColor[/*card*/ ctx[1].severity]}`);
		},
		m(target, anchor) {
			insert(target, span1, anchor);
			append(span1, span0);
			append(span0, t0);
			append(span1, t1);
			append(span1, t2);
			append(span1, t3);
		},
		p(ctx, [dirty]) {
			if (dirty & /*card, game, slot*/ 7 && t0_value !== (t0_value = (!/*card*/ ctx[1].player
			? "Team"
			: `#${(/*game*/ ctx[0].teamsLookup[/*game*/ ctx[0][/*slot*/ ctx[2]]].players.find(/*func*/ ctx[3]) || {}).number}`) + "")) set_data(t0, t0_value);

			if (dirty & /*card*/ 2 && t2_value !== (t2_value = cardTypeAbbrev[/*card*/ ctx[1].type] + "")) set_data(t2, t2_value);

			if (dirty & /*card, game*/ 3 && t3_value !== (t3_value = (/*card*/ ctx[1].player
			? `: ${yellowCardTimeRemaining(/*game*/ ctx[0], /*card*/ ctx[1])}`
			: "") + "")) set_data(t3, t3_value);

			if (dirty & /*card*/ 2 && span1_title_value !== (span1_title_value = `${/*card*/ ctx[1].severity}: ${/*card*/ ctx[1].type}`)) {
				attr(span1, "title", span1_title_value);
			}

			if (dirty & /*card*/ 2 && span1_class_value !== (span1_class_value = `tag is-${severityToColor[/*card*/ ctx[1].severity]}`)) {
				attr(span1, "class", span1_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(span1);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { game } = $$props;
	let { card } = $$props;
	let { slot } = $$props;
	const func = p => p.uuid === card.player;

	$$self.$$set = $$props => {
		if ('game' in $$props) $$invalidate(0, game = $$props.game);
		if ('card' in $$props) $$invalidate(1, card = $$props.card);
		if ('slot' in $$props) $$invalidate(2, slot = $$props.slot);
	};

	return [game, card, slot, func];
}

class CardBadge extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { game: 0, card: 1, slot: 2 });
	}
}

export default CardBadge;