import { toast } from "../store/store";
import { bent } from "./bent";

let baseurl = location.origin + "/node";

if (baseurl.indexOf("localhost") !== -1) {
	baseurl = "http://localhost:1337/node/";
}

const postRequest = bent({ baseurl, method: "POST", fetchOptions: { credentials: 'include' } });
const deleteRequest = bent({ baseurl, method: "DELETE", fetchOptions: { credentials: 'include' } });
const getRequest = bent({ baseurl });

const handleError = (errorResponse) => {
	console.error("Network Error", errorResponse);
	toast.set({ type: "error", title: "Network Error", message: errorResponse.responseBody.message });
	throw errorResponse;
};

const post = (path, payload) =>
	postRequest(path, payload).catch(handleError);

const del = (path, payload) =>
	deleteRequest(path, payload).catch(handleError);

const get = async (path, payload) =>
	getRequest(path, payload).catch(handleError);


export { post, get, del };