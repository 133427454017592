
const hasTimeGap = (game1, game2, normalDuration, plusMinus = 1000 * 60 * 5) => {
    const duration = Math.abs(game1.start.getTime() - game2.start.getTime());
    // if the duration doesn't match the norm by more than 5min there is a gap.
    return Math.abs(normalDuration - duration) > plusMinus;
};

const getFields = games =>
    [...games.reduce((fields, game) => fields.add(game.field), new Set())];

const findMostCommonDuration = (arr) => arr.reduce((max, current) =>
    max.count > current.count ? max : current, 0);

const mapStartToDate = (games) => games.map(g => ({ ...g, start: new Date(g.start) }));

// first by fields then by start times. 
const sortGames = (games) => {
    const arrayClone = [...games];
    arrayClone.sort((g1, g2) => ((g1.field - g2.field) * 1000000000) + (g1.start - g2.start));
    return arrayClone;
};

const goalsForSlot = (game, slot) => {
    if (game.forfeit === "no") {
        return game.goals.filter(goal => goal.team === game[slot]).length;
    } else if (game.forfeit === slot) {
        return 0;
    } else {
        return 7;
    }
}

const updateSlotRank = (ranksIn, game, slot) => {
    const otherSlot = slot === "t1" ? "t2" : "t1";
    const ranks = { ...ranksIn };
    const team = game[slot];
    const goalsFor = goalsForSlot(game, slot);
    const goalsAgainst = goalsForSlot(game, otherSlot);
    if (!ranks[team]) {
        ranks[team] = {
            team,
            gamesPlayed: 0,
            wins: 0,
            draws: 0,
            losses: 0,
            forfeits: 0,
            goalsFor: 0,
            goalsAgainst: 0,
            points: 0
        };
    }
    if (game.forfeit === slot) {
        ranks[team].forfeits++;
    }
    ranks[team].gamesPlayed++;
    ranks[team].goalsFor += goalsFor;
    ranks[team].goalsAgainst += goalsAgainst;
    if (goalsFor === goalsAgainst) {
        ranks[team].draws++;
    } else if (goalsFor > goalsAgainst) {
        ranks[team].wins++;
    } else if (goalsFor < goalsAgainst) {
        ranks[team].losses++;
    }
    return ranks;
};

const ladderForPool = (games, pool) => {
    const relevantGames = games.filter(g => g.gameState === "finished" && g.pool === pool);

    const teamToRank = relevantGames.reduce((ranksAcc, game) => {
        let ranks = updateSlotRank(ranksAcc, game, "t1");
        ranks = updateSlotRank(ranks, game, "t2");
        return ranks;
    }, {});
    const ranks = Object.values(teamToRank);
    const ranksWithPoints = ranks.map(r => ({ ...r, points: r.wins * 3 + r.draws * 1 }));
    ranksWithPoints.sort((r1, r2) => (r2.points - r1.points) * 10000 + ((r2.goalsFor - r2.goalsAgainst) - (r1.goalsFor - r1.goalsAgainst)));
    return ranksWithPoints;
};



const calcGoalsForTeams = (games) => games.map(g => {
    g.t1Goals = goalsForSlot(g, 't1');
    g.t2Goals = goalsForSlot(g, 't2');
    return g;
});

const getStartedPools = (games) =>
    [...games.reduce((pools, game) => game.gameState === "finished" ? pools.add(game.pool) : pools, new Set())];

const getNormalGameDuration = (sortedGames) => {
    const durationCounts = sortedGames.reduce((durationCounts, game, index) => {
        if (sortedGames.length <= index + 1) {
            return durationCounts;
        }
        const nextGame = sortedGames[index + 1];
        const gameDuration = Math.abs(nextGame.start.getTime() - game.start.getTime());
        if (!durationCounts[gameDuration]) {
            durationCounts[gameDuration] = { gameDuration, count: 0 };
        }
        durationCounts[gameDuration].count++;
        return durationCounts;
    }, {});

    const maxCount = findMostCommonDuration(Object.values(durationCounts).filter(dc => dc.gameDuration !== 0));
    console.log("Normal game duration;", maxCount);
    return maxCount.gameDuration;
};

const getGoogleUrl = address => "http://maps.google.com/?q=" + encodeURIComponent(address);

export { getGoogleUrl, getNormalGameDuration, hasTimeGap, sortGames, mapStartToDate, getFields, ladderForPool, getStartedPools, calcGoalsForTeams, goalsForSlot };