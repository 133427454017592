/* components/FinishedModal.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space,
	toggle_class
} from "svelte/internal";

import { navigate } from "svelte-routing";
import { createEventDispatcher } from "svelte";
import { get } from "../utils/api";
import { backToComp } from "../utils/dutyUtils";

function create_fragment(ctx) {
	let div2;
	let div0;
	let t0;
	let div1;
	let header;
	let p;
	let t2;
	let button0;
	let t3;
	let section;
	let t5;
	let footer;
	let button1;
	let t7;
	let button2;
	let t9;
	let button3;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = space();
			div1 = element("div");
			header = element("header");
			p = element("p");
			p.textContent = "The Game Has Finished!";
			t2 = space();
			button0 = element("button");
			t3 = space();
			section = element("section");
			section.textContent = "If all the goals and cards have been recorded accurately please click \"Sign Off\". Otherwise\n      click \"Not Ready\".";
			t5 = space();
			footer = element("footer");
			button1 = element("button");
			button1.textContent = "Not ready";
			t7 = space();
			button2 = element("button");
			button2.textContent = "Sign off & Exit";
			t9 = space();
			button3 = element("button");
			button3.textContent = "Sign off & Continue";
			attr(div0, "class", "modal-background");
			attr(p, "class", "modal-card-title");
			attr(button0, "class", "delete");
			attr(button0, "aria-label", "close");
			attr(header, "class", "modal-card-head");
			attr(section, "class", "modal-card-body");
			attr(button1, "class", "button");
			attr(button2, "class", "button is-primary is-outlined");
			attr(button3, "class", "button is-primary is-outlined");
			attr(footer, "class", "modal-card-foot buttons");
			attr(div1, "class", "modal-card");
			attr(div2, "class", "modal");
			toggle_class(div2, "is-active", /*open*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t0);
			append(div2, div1);
			append(div1, header);
			append(header, p);
			append(header, t2);
			append(header, button0);
			append(div1, t3);
			append(div1, section);
			append(div1, t5);
			append(div1, footer);
			append(footer, button1);
			append(footer, t7);
			append(footer, button2);
			append(footer, t9);
			append(footer, button3);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[5]),
					listen(button1, "click", /*click_handler_1*/ ctx[6]),
					listen(button2, "click", /*signOffExit*/ ctx[1]),
					listen(button3, "click", /*signOffContinue*/ ctx[2])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*open*/ 1) {
				toggle_class(div2, "is-active", /*open*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { open = false } = $$props;
	let { compId } = $$props;
	let { gameId } = $$props;
	const dispatch = createEventDispatcher();
	const getGamePath = () => `comps/${compId}/games/${gameId}`;

	const signOffExit = async () => {
		$$invalidate(0, open = false);
		backToComp(compId);
	};

	const signOffContinue = async () => {
		const nextGame = await get(getGamePath() + "/next");
		navigate(`/comps/${compId}/games/${nextGame.id}/duty`, { replace: true });
		dispatch("nextGame", nextGame);
		$$invalidate(0, open = false);
	};

	const click_handler = () => $$invalidate(0, open = false);
	const click_handler_1 = () => $$invalidate(0, open = false);

	$$self.$$set = $$props => {
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
		if ('compId' in $$props) $$invalidate(3, compId = $$props.compId);
		if ('gameId' in $$props) $$invalidate(4, gameId = $$props.gameId);
	};

	return [
		open,
		signOffExit,
		signOffContinue,
		compId,
		gameId,
		click_handler,
		click_handler_1
	];
}

class FinishedModal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { open: 0, compId: 3, gameId: 4 });
	}
}

export default FinishedModal;