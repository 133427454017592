import { navigate } from "svelte-routing";

const tenMinInSeconds = 10 * 60;
const severityTypes = ["green", "yellow", "red"];
const cardTypes = [
  "Hack",
  "Illegal Tackle",
  "Obstruction Holding",
  "Unsportsmanlike",
  "Illegal Substitution",
];

const cardTypeAbbrev = {
  Hack: "hack",
  "Obstruction Holding": "Obst.",
  "Illegal Tackle": "I.Tackle",
  Unsportsmanlike: "Unsp.",
  "Illegal Substitution": "I.Sub.",
};

const severityToColor = {
  green: "success",
  yellow: "warning",
  red: "danger",
};

const backToComp = compId => {
  if (window.history <= 2) {
    navigate(`comps/${compId}`);
  } else {
    window.history.back();
  }
};

const getActiveCards = (game, teamUuid) => {
  const yellowTeamCardsLastTwoMin = game.cards
    .filter(
      c =>
        c.team === teamUuid &&
        c.gameState === game.gameState &&
        c.timeRemainingSec >= game.timeSec && // if the clock goes back hide the card again..
        (c.timeRemainingSec || tenMinInSeconds) - (game.timeSec || tenMinInSeconds) <= 2 * 60
    )
    .sort((a, b) => a.timeRemainingSec - b.timeRemainingSec);
  let goalsScoredOtherTeamLastTwoMin = game.goals
    .filter(
      g =>
        g.team !== teamUuid &&
        g.gameState === game.gameState &&
        g.timeRemainingSec >= game.timeSec && // if the clock get pushed back don't count the goal yet.
        g.timeRemainingSec - game.timeSec <= 2 * 60
    )
    .sort((a, b) => a.timeRemainingSec - b.timeRemainingSec);
  const ejectionCards = yellowTeamCardsLastTwoMin.filter(card => card.ejection); // ejection
  const teamCards = yellowTeamCardsLastTwoMin.filter(card => !card.player); // team
  const yellowCardsRemainingAfterGoals = yellowTeamCardsLastTwoMin
    .filter(card => !card.ejection && card.player) // ignore team cards and the ejection cards.
    .filter(card => {
      const goalScoredAfterThisCard = goalsScoredOtherTeamLastTwoMin.find(
        g => g.timeRemainingSec <= card.timeRemainingSec
      );
      // once a goal has been matched with a card remove it from the list
      // so it can't be matched again.
      goalsScoredOtherTeamLastTwoMin = goalsScoredOtherTeamLastTwoMin.filter(
        g => g.id !== (goalScoredAfterThisCard || { id: -1 }).id
      );
      return !goalScoredAfterThisCard;
    });
  return [].concat(yellowCardsRemainingAfterGoals, ejectionCards, teamCards);
};

const secondsToText = timeSec => {
  const time = Math.max(0, timeSec),
    min = Math.floor(time / 60),
    sec = Math.floor(time % 60);
  let secStr = "" + sec,
    minStr = "" + min;

  if (secStr.length === 1) {
    secStr = "0" + secStr;
  }
  if (minStr.length === 1) {
    minStr = "0" + minStr;
  }
  let text = minStr + ":" + secStr;
  if (text === "10:00") {
    text = "Start";
  }
  return text;
};

const yellowCardTimeRemaining = (game, card) => {
  const durationSoFarSec =
    (card.timeRemainingSec || tenMinInSeconds) - (game.timeSec || tenMinInSeconds);
  const remainingSec = 60 * 2 - durationSoFarSec;
  return secondsToText(remainingSec);
};

export {
  backToComp,
  severityTypes,
  cardTypes,
  cardTypeAbbrev,
  severityToColor,
  secondsToText,
  getActiveCards,
  yellowCardTimeRemaining,
};
