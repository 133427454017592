/* components/Timer.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { onDestroy, createEventDispatcher } from "svelte";
import { resetTimer } from "../store/store";
import Icon from "fa-svelte";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { secondsToText } from "../utils/dutyUtils";

function create_fragment(ctx) {
	let buttons;
	let button0;
	let span0;
	let icon0;
	let t0;
	let span1;
	let t2;
	let button1;
	let span2;
	let icon1;
	let t3;
	let button2;
	let span3;
	let t4;
	let t5;
	let button3;
	let span4;
	let icon2;
	let current;
	let mounted;
	let dispose;
	icon0 = new Icon({ props: { class: "", icon: faSyncAlt } });
	icon1 = new Icon({ props: { class: "", icon: faMinus } });
	icon2 = new Icon({ props: { class: "", icon: faPlus } });

	return {
		c() {
			buttons = element("buttons");
			button0 = element("button");
			span0 = element("span");
			create_component(icon0.$$.fragment);
			t0 = space();
			span1 = element("span");
			span1.textContent = "Reset";
			t2 = space();
			button1 = element("button");
			span2 = element("span");
			create_component(icon1.$$.fragment);
			t3 = space();
			button2 = element("button");
			span3 = element("span");
			t4 = text(/*timeText*/ ctx[0]);
			t5 = space();
			button3 = element("button");
			span4 = element("span");
			create_component(icon2.$$.fragment);
			attr(span0, "class", "icon");
			attr(button0, "name", "reset");
			attr(button0, "class", "button is-warning is-outlined is-large timer");
			attr(span2, "class", "icon");
			attr(button1, "name", "decrement time");
			attr(button1, "class", "button is-danger is-outlined is-large timer");
			attr(button2, "name", "start stop timer");
			attr(button2, "class", "button is-normal is-outlined is-large timer");
			attr(span4, "class", "icon");
			attr(button3, "name", "increment time");
			attr(button3, "class", "button is-link is-outlined is-large timer");
		},
		m(target, anchor) {
			insert(target, buttons, anchor);
			append(buttons, button0);
			append(button0, span0);
			mount_component(icon0, span0, null);
			append(button0, t0);
			append(button0, span1);
			append(buttons, t2);
			append(buttons, button1);
			append(button1, span2);
			mount_component(icon1, span2, null);
			append(buttons, t3);
			append(buttons, button2);
			append(button2, span3);
			append(span3, t4);
			append(buttons, t5);
			append(buttons, button3);
			append(button3, span4);
			mount_component(icon2, span4, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[5]),
					listen(button1, "click", /*click_handler_1*/ ctx[6]),
					listen(button2, "click", /*click_handler_2*/ ctx[7]),
					listen(button3, "click", /*click_handler_3*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*timeText*/ 1) set_data(t4, /*timeText*/ ctx[0]);
		},
		i(local) {
			if (current) return;
			transition_in(icon0.$$.fragment, local);
			transition_in(icon1.$$.fragment, local);
			transition_in(icon2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon0.$$.fragment, local);
			transition_out(icon1.$$.fragment, local);
			transition_out(icon2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(buttons);
			destroy_component(icon0);
			destroy_component(icon1);
			destroy_component(icon2);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	const DEFAULT_TIME_SEC = 10 * 60;
	let { timeSec } = $$props;
	let interval;
	let lastTimeMs;
	let timeText = secondsToText(timeSec);

	const stop = () => {
		if (interval) {
			window.clearInterval(interval);
			interval = undefined;
		}
	};

	const start = () => {
		stop();
		lastTimeMs = new Date().getTime();
		interval = window.setInterval(() => tick(), 200);
		dispatch("started");
	};

	const reset = () => {
		stop();
		$$invalidate(4, timeSec = DEFAULT_TIME_SEC);
		refreshText();
	};

	const toggle = () => {
		if (interval) {
			stop();
		} else {
			start();
		}
	};

	const refreshText = () => {
		const newText = secondsToText(timeSec);

		if (newText !== timeText) {
			$$invalidate(0, timeText = newText);
			dispatch("tic", timeSec);
		}
	};

	const tick = () => {
		console.log("tick tock");
		const timeMs = new Date().getTime();
		const diffMs = timeMs - lastTimeMs;
		lastTimeMs = timeMs;
		$$invalidate(4, timeSec = timeSec - diffMs / 1000);

		if (timeSec <= 0) {
			console.log("finished!");
			$$invalidate(4, timeSec = 0);
			dispatch("finished");
			reset();
		}

		refreshText();
	};

	const incrementTime = incSec => {
		$$invalidate(4, timeSec += incSec);
		refreshText();
	};

	resetTimer.subscribe(() => {
		reset();
	});

	onDestroy(() => {
		stop();
	});

	const click_handler = () => reset();
	const click_handler_1 = () => incrementTime(-60);
	const click_handler_2 = () => toggle();
	const click_handler_3 = () => incrementTime(60);

	$$self.$$set = $$props => {
		if ('timeSec' in $$props) $$invalidate(4, timeSec = $$props.timeSec);
	};

	return [
		timeText,
		reset,
		toggle,
		incrementTime,
		timeSec,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3
	];
}

class Timer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { timeSec: 4 });
	}
}

export default Timer;