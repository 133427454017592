/* components/Loading.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Icon from "fa-svelte";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

function create_fragment(ctx) {
	let h5;
	let span;
	let icon;
	let t;
	let current;
	icon = new Icon({ props: { class: "", icon: faSpinner } });

	return {
		c() {
			h5 = element("h5");
			span = element("span");
			create_component(icon.$$.fragment);
			t = text("\n  Loading...");
			attr(span, "class", "icon fa-pulse");
			attr(h5, "class", /*title*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, h5, anchor);
			append(h5, span);
			mount_component(icon, span, null);
			append(h5, t);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h5);
			destroy_component(icon);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { size = "is-5" } = $$props;
	let title = `title ${size}`;

	$$self.$$set = $$props => {
		if ('size' in $$props) $$invalidate(1, size = $$props.size);
	};

	return [title, size];
}

class Loading extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { size: 1 });
	}
}

export default Loading;