/* components/Toast.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "svelte/internal";

import { toast } from "../store/store";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (23:2) {#each toasts as toast}
function create_each_block(ctx) {
	let div;
	let button;
	let t0;
	let t1_value = /*toast*/ ctx[5].message + "";
	let t1;
	let t2;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[2](/*toast*/ ctx[5]);
	}

	return {
		c() {
			div = element("div");
			button = element("button");
			t0 = space();
			t1 = text(t1_value);
			t2 = space();
			attr(button, "class", "delete");
			attr(div, "class", "notification");
			toggle_class(div, "is-danger", /*toast*/ ctx[5].type === "error");
			toggle_class(div, "is-success", /*toast*/ ctx[5].type === "success");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			append(div, t0);
			append(div, t1);
			append(div, t2);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*toasts*/ 1 && t1_value !== (t1_value = /*toast*/ ctx[5].message + "")) set_data(t1, t1_value);

			if (dirty & /*toasts*/ 1) {
				toggle_class(div, "is-danger", /*toast*/ ctx[5].type === "error");
			}

			if (dirty & /*toasts*/ 1) {
				toggle_class(div, "is-success", /*toast*/ ctx[5].type === "success");
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*toasts*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "toastContainer svelte-c90ttb");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*toasts, deleteToast*/ 3) {
				each_value = /*toasts*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let toasts = [];
	let id = 0;
	const toastLifeMS = 4 * 1000; // four seconds.

	const deleteToast = toastId => {
		$$invalidate(0, toasts = toasts.filter(t => t.id !== toastId));
	};

	toast.subscribe(toast => {
		if (toast.type) {
			const toastId = id++;
			const t = window.setTimeout(() => deleteToast(toastId), toastLifeMS);
			$$invalidate(0, toasts = [...toasts, { ...toast, id: toastId, timer: t }]);
		}
	});

	const click_handler = toast => deleteToast(toast.id);
	return [toasts, deleteToast, click_handler];
}

class Toast extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Toast;