
class StatusError extends Error {
    constructor(res, body, ...params) {
        super(...params)
        this.message = `Incorrect statusCode: ${res.status}`
        this.statusCode = res.status
        this.res = res
        this.responseBody = body;
    }
}

const getBody = async (encoding, resp) => {
    if (encoding === 'json') return resp.json()
    else if (encoding === 'buffer') return resp.arrayBuffer()
    else if (encoding === 'string') return resp.text()
    else return resp
}

const bent = ({ statusCodes, method = "GET", encoding = "json", headers = {}, baseurl = "", fetchOptions = {} } = {}) => async (_url, body, _headers = {}) => {
    _url = baseurl.replace(/\/$/, '') + "/" + (_url.replace(/^\//, '') || '')
    let parsed = new URL(_url)

    if (!headers) headers = {}
    if (parsed.username) {
        headers.Authorization = 'Basic ' + btoa(parsed.username + ':' + parsed.password)
        parsed = new URL(parsed.protocol + '//' + parsed.host + parsed.pathname + parsed.search)
    }
    if (parsed.protocol !== 'https:' && parsed.protocol !== 'http:') {
        throw new Error(`Unknown protocol, ${parsed.protocol}`)
    }

    if (body) {
        if (body instanceof ArrayBuffer ||
            ArrayBuffer.isView(body) ||
            typeof body === 'string'
        ) {
            // noop
        } else if (typeof body === 'object') {
            body = JSON.stringify(body)
            headers['Content-Type'] = 'application/json'
        } else {
            throw new Error('Unknown body type.')
        }
    }

    _headers = new Headers({ ...(headers || {}), ..._headers })

    const resp = await fetch(parsed, { method, headers: _headers, body, ...fetchOptions })
    resp.statusCode = resp.status
    if (statusCodes && !statusCodes.includes(resp.status) || !statusCodes && !resp.ok) {
        throw new StatusError(resp, await getBody(encoding, resp));
    }
    return getBody(encoding, resp);
}

export { bent };
