/* components/TeamSlot.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text,
	toggle_class
} from "svelte/internal";

function create_fragment(ctx) {
	let div;
	let t;
	let div_data_team_id_value;

	return {
		c() {
			div = element("div");
			t = text(/*teamName*/ ctx[3]);
			attr(div, "data-team-id", div_data_team_id_value = /*game*/ ctx[0][/*gameSlot*/ ctx[1]] || /*teamName*/ ctx[3]);
			toggle_class(div, "is-capitalized", true);
			toggle_class(div, "is-italic", /*isPlaceholder*/ ctx[4]);
			toggle_class(div, "has-background-warning", /*game*/ ctx[0][/*gameSlot*/ ctx[1]] === /*selectedTeam*/ ctx[2] || /*teamName*/ ctx[3] === /*selectedTeam*/ ctx[2]);
			toggle_class(div, "has-text-weight-semibold", ['t1', 't2'].includes(/*gameSlot*/ ctx[1]));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, [dirty]) {
			if (dirty & /*teamName*/ 8) set_data(t, /*teamName*/ ctx[3]);

			if (dirty & /*game, gameSlot, teamName*/ 11 && div_data_team_id_value !== (div_data_team_id_value = /*game*/ ctx[0][/*gameSlot*/ ctx[1]] || /*teamName*/ ctx[3])) {
				attr(div, "data-team-id", div_data_team_id_value);
			}

			if (dirty & /*isPlaceholder*/ 16) {
				toggle_class(div, "is-italic", /*isPlaceholder*/ ctx[4]);
			}

			if (dirty & /*game, gameSlot, selectedTeam, teamName*/ 15) {
				toggle_class(div, "has-background-warning", /*game*/ ctx[0][/*gameSlot*/ ctx[1]] === /*selectedTeam*/ ctx[2] || /*teamName*/ ctx[3] === /*selectedTeam*/ ctx[2]);
			}

			if (dirty & /*gameSlot*/ 2) {
				toggle_class(div, "has-text-weight-semibold", ['t1', 't2'].includes(/*gameSlot*/ ctx[1]));
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let isPlaceholder;
	let teamName;
	let { game = {} } = $$props;
	let { teams = {} } = $$props;
	let { gameSlot } = $$props;
	let { selectedTeam } = $$props;

	$$self.$$set = $$props => {
		if ('game' in $$props) $$invalidate(0, game = $$props.game);
		if ('teams' in $$props) $$invalidate(5, teams = $$props.teams);
		if ('gameSlot' in $$props) $$invalidate(1, gameSlot = $$props.gameSlot);
		if ('selectedTeam' in $$props) $$invalidate(2, selectedTeam = $$props.selectedTeam);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*teams, game, gameSlot*/ 35) {
			$: $$invalidate(4, isPlaceholder = teams[game[gameSlot]] === undefined);
		}

		if ($$self.$$.dirty & /*teams, game, gameSlot*/ 35) {
			$: $$invalidate(3, teamName = teams[game[gameSlot]]
			? teams[game[gameSlot]].name
			: game[gameSlot + "Placeholder"]);
		}
	};

	return [game, gameSlot, selectedTeam, teamName, isPlaceholder, teams];
}

class TeamSlot extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			game: 0,
			teams: 5,
			gameSlot: 1,
			selectedTeam: 2
		});
	}
}

export default TeamSlot;