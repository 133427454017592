/* components/Error.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Icon from "fa-svelte";

import {
	faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";

function create_fragment(ctx) {
	let p;
	let icon;
	let t0;
	let t1;
	let current;

	icon = new Icon({
			props: {
				class: "has-text-danger mr-1",
				icon: faExclamationTriangle
			}
		});

	return {
		c() {
			p = element("p");
			create_component(icon.$$.fragment);
			t0 = space();
			t1 = text(/*message*/ ctx[0]);
			attr(p, "class", "is-flex is-centered");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			mount_component(icon, p, null);
			append(p, t0);
			append(p, t1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*message*/ 1) set_data(t1, /*message*/ ctx[0]);
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(p);
			destroy_component(icon);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { message } = $$props;

	$$self.$$set = $$props => {
		if ('message' in $$props) $$invalidate(0, message = $$props.message);
	};

	return [message];
}

class Error extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { message: 0 });
	}
}

export default Error;