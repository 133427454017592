/* components/PasswordModal.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_store_value,
	space,
	text,
	toggle_class
} from "svelte/internal";

import { post } from "../utils/api";
import { loggedIn } from "../store/store";

function create_fragment(ctx) {
	let div3;
	let div0;
	let t0;
	let div2;
	let header;
	let p;
	let t1_value = /*getLabel*/ ctx[2]() + "";
	let t1;
	let t2;
	let t3;
	let button0;
	let t4;
	let section;
	let form;
	let label;
	let t5;
	let t6_value = /*getLabel*/ ctx[2]().toLowerCase() + "";
	let t6;
	let t7;
	let t8;
	let div1;
	let input;
	let t9;
	let footer;
	let button1;
	let t11;
	let button2;
	let mounted;
	let dispose;

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			t0 = space();
			div2 = element("div");
			header = element("header");
			p = element("p");
			t1 = text(t1_value);
			t2 = text(" Password");
			t3 = space();
			button0 = element("button");
			t4 = space();
			section = element("section");
			form = element("form");
			label = element("label");
			t5 = text("Please enter the ");
			t6 = text(t6_value);
			t7 = text(" password to continue");
			t8 = space();
			div1 = element("div");
			input = element("input");
			t9 = space();
			footer = element("footer");
			button1 = element("button");
			button1.textContent = "Cancel";
			t11 = space();
			button2 = element("button");
			button2.textContent = "Sign in";
			attr(div0, "class", "modal-background");
			attr(p, "class", "modal-card-title");
			attr(button0, "class", "delete");
			attr(button0, "aria-label", "close");
			attr(header, "class", "modal-card-head");
			attr(label, "class", "label");
			attr(label, "for", "pw");
			attr(input, "class", "input");
			attr(input, "name", "pw");
			attr(input, "type", "password");
			attr(input, "placeholder", "");
			attr(div1, "class", "control");
			attr(form, "class", "field column");
			attr(section, "class", "modal-card-body");
			attr(button1, "class", "button");
			attr(button2, "class", "button is-primary is-outlined");
			attr(footer, "class", "modal-card-foot");
			attr(div2, "class", "modal-card");
			attr(div3, "class", "modal");
			toggle_class(div3, "is-active", /*open*/ ctx[0] && !/*isLoggedIn*/ ctx[3]());
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div3, t0);
			append(div3, div2);
			append(div2, header);
			append(header, p);
			append(p, t1);
			append(p, t2);
			append(header, t3);
			append(header, button0);
			append(div2, t4);
			append(div2, section);
			append(section, form);
			append(form, label);
			append(label, t5);
			append(label, t6);
			append(label, t7);
			append(form, t8);
			append(form, div1);
			append(div1, input);
			set_input_value(input, /*password*/ ctx[1]);
			append(div2, t9);
			append(div2, footer);
			append(footer, button1);
			append(footer, t11);
			append(footer, button2);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[7]),
					listen(input, "input", /*input_input_handler*/ ctx[8]),
					listen(form, "submit", prevent_default(/*login*/ ctx[4])),
					listen(button1, "click", /*click_handler_1*/ ctx[9]),
					listen(button2, "click", /*login*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*getLabel*/ 4 && t1_value !== (t1_value = /*getLabel*/ ctx[2]() + "")) set_data(t1, t1_value);
			if (dirty & /*getLabel*/ 4 && t6_value !== (t6_value = /*getLabel*/ ctx[2]().toLowerCase() + "")) set_data(t6, t6_value);

			if (dirty & /*password*/ 2 && input.value !== /*password*/ ctx[1]) {
				set_input_value(input, /*password*/ ctx[1]);
			}

			if (dirty & /*open, isLoggedIn*/ 9) {
				toggle_class(div3, "is-active", /*open*/ ctx[0] && !/*isLoggedIn*/ ctx[3]());
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let getLabel;
	let $loggedIn;
	component_subscribe($$self, loggedIn, $$value => $$invalidate(10, $loggedIn = $$value));
	let { type = "duty" } = $$props;
	let { open = false } = $$props;
	let { compId } = $$props;
	let password = "";

	const getKey = () => {
		return `${compId}:${type}`;
	};

	const isLoggedIn = () => {
		const lIn = !!$loggedIn[getKey()];
		console.log("isLogged in?", getKey(), lIn);
		return lIn;
	};

	const login = async () => {
		await post("auth/login", { username: getKey(), password });
		$$invalidate(0, open = false);
		set_store_value(loggedIn, $loggedIn[getKey()] = true, $loggedIn);
	};

	const click_handler = () => $$invalidate(0, open = false);

	function input_input_handler() {
		password = this.value;
		$$invalidate(1, password);
	}

	const click_handler_1 = () => $$invalidate(0, open = false);

	$$self.$$set = $$props => {
		if ('type' in $$props) $$invalidate(5, type = $$props.type);
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
		if ('compId' in $$props) $$invalidate(6, compId = $$props.compId);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*type*/ 32) {
			$: $$invalidate(2, getLabel = () => type === "duty" ? "Duty" : "Admin");
		}
	};

	return [
		open,
		password,
		getLabel,
		isLoggedIn,
		login,
		type,
		compId,
		click_handler,
		input_input_handler,
		click_handler_1
	];
}

class PasswordModal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { type: 5, open: 0, compId: 6 });
	}
}

export default PasswordModal;