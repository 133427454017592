/* components/NavLink.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	create_component,
	create_slot,
	destroy_component,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import { Link } from "svelte-routing";

function create_default_slot(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[1].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				style: "flex-grow: 1;",
				to: /*to*/ ctx[0],
				getProps,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const link_changes = {};
			if (dirty & /*to*/ 1) link_changes.to = /*to*/ ctx[0];

			if (dirty & /*$$scope*/ 4) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

function getProps({ href, isPartiallyCurrent, isCurrent }) {
	const isActive = href === "/"
	? isCurrent
	: isPartiallyCurrent || isCurrent;

	console.log(href, isPartiallyCurrent, isCurrent, isActive);

	// The object returned here is spread on the anchor element's attributes
	if (isActive) {
		return { class: "navbar-item has-text-dark" };
	}

	return { class: "navbar-item has-text-grey" };
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { to = "" } = $$props;

	$$self.$$set = $$props => {
		if ('to' in $$props) $$invalidate(0, to = $$props.to);
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [to, slots, $$scope];
}

class NavLink extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { to: 0 });
	}
}

export default NavLink;