/* components/PlayerLadder.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "svelte/internal";

import { onMount } from "svelte";
import { get } from "../utils/api";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (27:4) {#each playerLadder as player}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*player*/ ctx[2].name + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*player*/ ctx[2].teamName + "";
	let t2;
	let t3;
	let td2;
	let t4_value = /*player*/ ctx[2].goals + "";
	let t4;
	let t5;
	let td3;
	let t6_value = /*player*/ ctx[2].cards + "";
	let t6;
	let t7;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td3 = element("td");
			t6 = text(t6_value);
			t7 = space();
			attr(td0, "class", "is-capitalized");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
			append(tr, td3);
			append(td3, t6);
			append(tr, t7);
		},
		p(ctx, dirty) {
			if (dirty & /*playerLadder*/ 1 && t0_value !== (t0_value = /*player*/ ctx[2].name + "")) set_data(t0, t0_value);
			if (dirty & /*playerLadder*/ 1 && t2_value !== (t2_value = /*player*/ ctx[2].teamName + "")) set_data(t2, t2_value);
			if (dirty & /*playerLadder*/ 1 && t4_value !== (t4_value = /*player*/ ctx[2].goals + "")) set_data(t4, t4_value);
			if (dirty & /*playerLadder*/ 1 && t6_value !== (t6_value = /*player*/ ctx[2].cards + "")) set_data(t6, t6_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let table;
	let thead;
	let t7;
	let tbody;
	let each_value = /*playerLadder*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><td>Player</td> 
      <td>Team</td> 
      <td>Goals</td> 
      <td>Cards</td></tr>`;

			t7 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(table, "class", "table is-striped is-hoverable is-fullwidth is-centered-cells");
			set_style(table, "font-size", ".8rem");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, thead);
			append(table, t7);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*playerLadder*/ 1) {
				each_value = /*playerLadder*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(table);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let playerLadder;
	let { compId } = $$props;

	onMount(async () => {
		$$invalidate(0, playerLadder = await get(`comps/${compId}/playerLadder`));
	});

	$$self.$$set = $$props => {
		if ('compId' in $$props) $$invalidate(1, compId = $$props.compId);
	};

	$: $$invalidate(0, playerLadder = []);
	return [playerLadder, compId];
}

class PlayerLadder extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { compId: 1 });
	}
}

export default PlayerLadder;